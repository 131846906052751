import * as ko from 'knockout';
import * as _ from 'underscore';

import {FileTypes} from "Core/Common/Enums/FileTypes";

import {Paginator} from 'Core/Components/Paginator/Paginator';
import {ViewGridModel} from 'Core/Controls/Grid/Models/ViewGridModel';
import {EntityMetadataModel} from 'Core/Controls/Grid/Models/GridDataModel/EntityMetadataModel';
import {Event} from 'Core/Common/Event';
import {EVENTS} from 'Core/Controls/Grid/Toolbar/Constants';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {IControl} from 'Core/Controls/IControl';
import {LABELS} from "Core/Components/Translation/Locales";

import 'Core/Components/QueryDropdowns/QueryDropdowns';

import {UserManager, UserRoles} from 'User/UserManager';

import Template from 'Core/Controls/Grid/Toolbar/Templates/Toolbar.html';

ko.templates['Core/Controls/Grid/Toolbar/Templates/Toolbar'] = Template;

export class Toolbar extends Event {
    private _enableEditTableView: KnockoutObservable<boolean>;
    private _enableDeleteTableView: KnockoutObservable<boolean>;
    private _enableLockItem: KnockoutObservable<boolean>;

    Paginator: KnockoutObservable<Paginator>;
    private _tableView: KnockoutObservable<ViewGridModel>;
    private _viewGridList: KnockoutObservableArray<ViewGridModel>;
    private _gridSubjectEntityMetadata: KnockoutObservable<EntityMetadataModel>;
    private _searchPhrase: KnockoutObservable<string>;
    private _showAddButton: KnockoutObservable<boolean>;
    private _showEditAllButton: KnockoutObservable<boolean>;
    private _recordsInBasket: KnockoutObservable<number>;
    private _isPortlet: boolean;
    private _isListScreen: boolean;
    private _isSpecialScreen: boolean;
    private _isDashboardScreen: boolean;
    private _isConsultScreen: boolean;
    private _isEditScreen: boolean;
    private _showLockItem: boolean;
    private _isEnabled: KnockoutObservable<boolean>;
    private _isAddingRecordAllowed: KnockoutObservable<boolean>;
    private _labels = LABELS;
    private _showTogglePrioritiesButton: KnockoutObservable<boolean>;
    private _isGridPrioritiesDisabled: KnockoutObservable<boolean>;
    private _showBulkEditButton: KnockoutObservable<boolean>;
    private _disableBulkEditButton: KnockoutObservable<boolean>;
    private _showResetFilterButton: KnockoutObservable<boolean>;
    private _disabledShowAllColumnsBtn: KnockoutObservable<boolean>;
    private _canShow: KnockoutObservable<boolean>;
    private _canHide: KnockoutObservable<boolean>;
    private _showRetired: KnockoutObservable<boolean>;
    private _showRetiredButton: KnockoutObservable<boolean>;
    private _controls: Array<IControl>;
    private _editAll: KnockoutObservable<boolean>;
    private _showCopyButton: KnockoutObservable<boolean>;
    private _showControls: KnockoutObservable<boolean>;
    private _isMultipleUnlinkModeActive: KnockoutObservable<boolean> = ko.observable(false);
    private _isMultipleUnlinkButtonVisible: KnockoutComputed<boolean>;

    constructor(
        private _showTableViewLookup: boolean = true,
        private _showFunctionLookup: boolean = true,
        showCopyButton: boolean,
        private _showLinkButton: KnockoutObservable<boolean> = ko.observable(true),
        private _showUnlinkMultipleRecordsButton: KnockoutObservable<boolean> = ko.observable(true),
        private _showLinkParentButton: KnockoutObservable<boolean> = ko.observable(true),
        private _hasOneParent: KnockoutObservable<boolean> = ko.observable(false),
        private _showAddAndLinkButton: KnockoutObservable<boolean> = ko.observable(true),
        private _showScanAndLinkButton: KnockoutObservable<boolean> = ko.observable(false),
        private _showAddQueryButton: KnockoutObservable<boolean> = ko.observable(false),
        private _showSearchInput: KnockoutObservable<boolean> = ko.observable(true),
        private _isEditable: KnockoutObservable<boolean> = ko.observable(true),
        private _addingRecordAllowed: KnockoutObservable<boolean> = ko.observable(true),
        private _exportDataAllowed: KnockoutObservable<boolean> = ko.observable(false),
        private _showTableViewElement: KnockoutObservable<boolean> = ko.observable(false),
        private _activeScreen: string = '',
        isGridPrioritiesEnabled: boolean,
        showTogglePrioritiesButton: boolean,
        showBulkEditButton: KnockoutObservable<boolean> = ko.observable(false),
        disableBulkEditButton: KnockoutObservable<boolean> = ko.observable(false),
        showResetFilterButton: KnockoutObservable<boolean> = ko.observable(false),
        disabledShowAllColumnsBtn: boolean,
        showRetiredButton: boolean,
        controls?: Array<IControl>
    ) {
        super();

        this._tableView = ko.observable(null);
        this._enableEditTableView = ko.observable(false);
        this._enableDeleteTableView = ko.observable(false);
        this._enableLockItem = ko.observable(false);
        this._isAddingRecordAllowed = ko.observable(false);
        this._viewGridList = ko.observableArray([]);
        this._gridSubjectEntityMetadata = ko.observable(null);
        this._searchPhrase = ko.observable("");
        this._showAddButton = ko.observable(false);
        this._showEditAllButton = ko.observable(false);
        this._recordsInBasket = ko.observable(0);
        this._isPortlet = this._activeScreen === ScreenTypes[ScreenTypes.Portlet];
        this._isListScreen = this._activeScreen === ScreenTypes[ScreenTypes.ListScreen];
        this._isSpecialScreen = this._activeScreen === ScreenTypes[ScreenTypes.SpecialScreen];
        this._isDashboardScreen = this._activeScreen === ScreenTypes[ScreenTypes.Dashboard];
        this._isConsultScreen = this._activeScreen === ScreenTypes[ScreenTypes.ConsultScreen];
        this._isEditScreen = this._activeScreen === ScreenTypes[ScreenTypes.EditScreen];
        this._showTogglePrioritiesButton = ko.observable(showTogglePrioritiesButton);
        this._isGridPrioritiesDisabled = ko.observable(isGridPrioritiesEnabled);
        this._showBulkEditButton = showBulkEditButton;
        this._disableBulkEditButton = disableBulkEditButton;
        this._showResetFilterButton = showResetFilterButton;
        this._disabledShowAllColumnsBtn = ko.observable(disabledShowAllColumnsBtn);
        this.Paginator = ko.observable(new Paginator());
        this._canHide = ko.observable(false);
        this._canShow = ko.observable(false);
        this._showRetired = ko.observable(false);
        this._showRetiredButton = ko.observable(showRetiredButton);
        this._controls = controls;
        this._showLockItem = UserManager.Instance.IsUserInRole(UserRoles.SuperUser);
        this._editAll = ko.observable(true);
        this._showCopyButton = ko.observable(showCopyButton);
        this._showControls = ko.observable(true);
        this._isMultipleUnlinkButtonVisible = ko.computed(() => { return this._showUnlinkMultipleRecordsButton && (this._isConsultScreen || this._isEditScreen)});

        this._viewGridList([new ViewGridModel(0, LABELS.DEFAULT_VIEW)]);
        this._tableView(_.first(this._viewGridList()));

        this.AddEvent(EVENTS.NEW_TABLE_VIEW);
        this.AddEvent(EVENTS.EDIT_TABLE_VIEW);
        this.AddEvent(EVENTS.COPY_TABLE_VIEW);
        this.AddEvent(EVENTS.ADD_AND_LINK_RECORD);
        this.AddEvent(EVENTS.ADD_QUERY);
        this.AddEvent(EVENTS.LINK_RECORD);
        this.AddEvent(EVENTS.UNLINK_MULTIPLE_RECORDS);
        this.AddEvent(EVENTS.CONFIRM_MULTIPLE_UNLINK);
        this.AddEvent(EVENTS.CANCEL_MULTIPLE_UNLINK);
        this.AddEvent(EVENTS.LINK_PARENT_RECORD);
        this.AddEvent(EVENTS.SCAN_AND_LINK_RECORD);
        this.AddEvent(EVENTS.COPY_LATEST_RECORD);
        this.AddEvent(EVENTS.SELECT_TABLE_VIEW);
        this.AddEvent(EVENTS.SEARCH_BY_PHRASE);
        this.AddEvent(EVENTS.NEW_RECORD);
        this.AddEvent(EVENTS.DELETE_TABLE_VIEW);
        this.AddEvent(EVENTS.LOCK_TABLE_VIEW);
        this.AddEvent(EVENTS.UNLOCK_TABLE_VIEW);
        this.AddEvent(EVENTS.EXPORT_DATA);
        this.AddEvent(EVENTS.TOGGLE_PRIORITIES);
        this.AddEvent(EVENTS.RESET_FILTER);
        this.AddEvent(EVENTS.BULK_EDIT);
        this.AddEvent(EVENTS.TOGGLE_RETIRED);
        this.AddEvent(EVENTS.EDIT_ALL);
        this.AddEvent(EVENTS.SAVE_ALL);

        this._isEnabled = ko.observable(false);

        this._tableView.subscribe((newValue) => {
            this._enableEditTableView(newValue.IsEditingAllowed);
            this._enableDeleteTableView(newValue.IsDeletingAllowed);
            this._enableLockItem(this._showLockItem && this._tableView().Id > 0);

            this.Trigger(EVENTS.SELECT_TABLE_VIEW, newValue);
        });
    }

    get CreateNewMessage() {
        const entityName = this.EntityNameTranslation;
        return this._labels.CREATE_NEW.replace('{entityName}', entityName);
    }

    get AddAndLinkMessage() {
        const entityName = this.EntityNameTranslation;
        const searchValue = /\{entityName\}/gi;

        return this._labels.ADD_AND_LINK.replace(searchValue, entityName);
    }

    get LinkMessage() {
        const entityName = this.EntityNameTranslation;
        const searchValue = /\{entityName\}/gi;

        return this._labels.LINK.replace(searchValue, entityName);
    }

    get EntityNameTranslation(){
        const gridSubjectEntityNameTranslation = this._gridSubjectEntityMetadata() && this._gridSubjectEntityMetadata().NameTranslation;
        const gridSubjectEntityName = this._gridSubjectEntityMetadata() && this._gridSubjectEntityMetadata().Name;

        return gridSubjectEntityNameTranslation || gridSubjectEntityName || '';
    }

    ShowSearchInput(value: boolean) {
        this._showSearchInput(value);
    }

    ShowRetiredButton(value: boolean) {
        this._showRetiredButton(value);
    }

    SetDefaultTableView() {
        this._tableView(_.first(this._viewGridList()));
    }

    SetTableViewList(tableViewList: Array<ViewGridModel>, selectedTableView: ViewGridModel, isAddingRecordAllowed: boolean) {
        this._viewGridList(tableViewList);
        this._tableView(selectedTableView);
        this._isAddingRecordAllowed(isAddingRecordAllowed);
    }

    SetEnabledAddButton(enableAddButton: boolean) {
        this._showAddButton(enableAddButton);
    }

    SetEnableEditAllButton(enableEditAllButton: boolean) {
        this._showEditAllButton(enableEditAllButton);
    }

    SetGridSubjectEntityMetadata(value: EntityMetadataModel) {
        this._gridSubjectEntityMetadata(value);
    }

    GetTemplateName(): string {
        return 'Core/Controls/Grid/Toolbar/Templates/Toolbar';
    }

    NewTableView() {
        this.Trigger(EVENTS.NEW_TABLE_VIEW);
    }

    EditTableView() {
        this.Trigger(EVENTS.EDIT_TABLE_VIEW, this._tableView());
    }

    CopyTableView() {
        this.Trigger(EVENTS.COPY_TABLE_VIEW, this._tableView());
    }

    DeleteTableView() {
        this.Trigger(EVENTS.DELETE_TABLE_VIEW, this._tableView());
    }

    ToggleLock() {
        if (this._tableView().Locked) {
            this.Trigger(EVENTS.UNLOCK_TABLE_VIEW, this._tableView());
        } else {
            this.Trigger(EVENTS.LOCK_TABLE_VIEW, this._tableView());
        }
    }

    public ToggleAllShownColums(canHide: boolean, canShow: boolean) {
        this._canHide(canHide);
        this._canShow(canShow);
    }

    EnterKey() {
        this.Trigger(EVENTS.SEARCH_BY_PHRASE, {SearchPhrase: this._searchPhrase().trim()});
    }

    AddAndLinkRecord() {
        this.Trigger(EVENTS.ADD_AND_LINK_RECORD);
    }

    LinkRecord() {
        this.Trigger(EVENTS.LINK_RECORD);
    }

    UnlinkMultipleRecords() {
        this.Trigger(EVENTS.UNLINK_MULTIPLE_RECORDS);

        this._isMultipleUnlinkModeActive(true);
    }

    LinkParentRecord() {
        this.Trigger(EVENTS.LINK_PARENT_RECORD);
    }

    ScanAndLinkRecord() {
        this.Trigger(EVENTS.SCAN_AND_LINK_RECORD);
    }

    CopyLatestRecord() {
        this.Trigger(EVENTS.COPY_LATEST_RECORD);
    }

    AddQuery() {
        this.Trigger(EVENTS.ADD_QUERY);
    }

    NewRecord() {
        this.Trigger(EVENTS.NEW_RECORD);
    }

    BulkEdit() {
        if (!this._disableBulkEditButton()) {
            this.Trigger(EVENTS.BULK_EDIT);
        }
    }

    AfterRender() {
    }

    Enable() {
        this._isEnabled(true);
    }

    ExportToCSV() {
        this.Trigger(EVENTS.EXPORT_DATA, {Destination: FileTypes.CSV});
    }

    ExportToExcel() {
        this.Trigger(EVENTS.EXPORT_DATA, {Destination: FileTypes.EXCEL});
    }

    ResetFilter() {
        this.Trigger(EVENTS.RESET_FILTER);
    }

    TogglePrioritues() {
        this._isGridPrioritiesDisabled(!this._isGridPrioritiesDisabled());

        this.Trigger(EVENTS.TOGGLE_PRIORITIES, {state: this._isGridPrioritiesDisabled()});
    }

    ToggleRetired() {
        this._showRetired(!this._showRetired());
        this.Trigger(EVENTS.TOGGLE_RETIRED, {State: this._showRetired()});
    }

    ToggleEditAll() {
        if (this._editAll()) {
            this._editAll(false);
            this.Trigger(EVENTS.EDIT_ALL);
        } else {
            this.Trigger(EVENTS.SAVE_ALL);
        }
    }

    OnConfirm() {
        this.Trigger(EVENTS.CONFIRM_MULTIPLE_UNLINK);

        this._isMultipleUnlinkModeActive(false);
    }

    OnCancel() {
        this.Trigger(EVENTS.CANCEL_MULTIPLE_UNLINK);

        this._isMultipleUnlinkModeActive(false);
    }

    ShowCopyButton(value: boolean) {
        this._showCopyButton(value);
    }

    ShowContorls(value: boolean) {
        this._showControls(value);
    }

    ShowTogglePrioritiesButton(value: boolean) {
        this._showTogglePrioritiesButton(value);
    }


    set EditAll(value: boolean) {
        this._editAll(value);
    }
}