import * as $ from "jquery";
import * as ko from 'knockout';
import * as _ from 'underscore';

import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';

import {P} from "Core/Common/Promise";
import {EventArgs} from "Core/Common/Event";

import {BaseScreen} from 'Core/Screens/BaseScreen';
import {SearchScreen} from 'Core/Screens/SearchScreen/SearchScreen';
import {Search} from 'Core/Controls/Search/Search';
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {IControl} from 'Core/Controls/IControl';
import {EditScreen} from 'Core/Screens/EditScreen/EditScreen';
import {
    CONTROL_TYPES,
    LIFE_STATUS_GROUPS,
    RenderModes,
    CARD_SCREEN_PROPERTY_TYPE,
    SCREEN_PROPERTY_TYPE
} from 'Core/Constant';
import {TypeScreen} from 'Core/Screens/TypeScreen/TypeScreen';
import {Notifier} from 'Core/Common/Notifier';
import {RecordStore} from 'Core/Screens/Stores/RecordStore';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {
	DeletionModeDialog,
	EVENTS as DELETION_MODE_DIALOG_EVENTS
} from 'Core/Components/Dialogs/DeletionModeDialog/DeletionModeDialog';
import {DeletionModeModel} from "Core/Screens/Models/DeletionModeModel";
import {BlockUI} from 'Core/Common/BlockUi';
import {PathRunner} from 'Core/Components/PathRunner/PathRunner';
import {PathRunnerUnit} from 'Core/Components/PathRunner/PathRunnerUnit';
import {LABELS, NOTIFICATIONS} from 'Core/Components/Translation/Locales';
import {RecordSecurityStore} from 'Core/Controls/RecordSecurity/Stores/RecordSecurityStore';
import {EVENTS as CONSULT_SCREEN_EVENTS} from 'Core/Screens/ConsultScreen/Events';
import {EVENTS as DROP_EVENTS} from 'Core/Controls/Drop/Events';

import {ScreenDataModel} from 'Core/ScreenManager/Models/ScreenDataModel';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel'
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel'
import {DataModes} from 'Core/Enums/DataModes';
import {ProgressBar} from 'Core/Components/ProgressBar/ProgressBar';
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";
import { ButtonFollowUp } from 'Core/Controls/ButtonFollowUp/ButtonFollowUp';
import { FollowUpRecordModel } from 'Core/Controls/ButtonFollowUp/Models/FollowUpRecordModel';
import { FollowUpRecordRequestModel, FollowUpActionSubjectModel } from "Core/Screens/ConsultScreen/Models/FollowUpRecordRequestModel";
import { ScreenDataStore } from "Core/Screens/ConsultScreen/Stores/ScreenDataStore";
import { FollowupModes } from 'Core/Constants/FollowupModes';
import { ProfileSelectorScreen } from '../ProfileSelectorScreen/ProfileSelectorScreen';
import { SubjectActionModel } from "Core/Controls/ButtonFollowUp/Models/SubjectActionModel";
import { UpdateRecordLifestatusRequestModel} from "Core/Screens/ConsultScreen/Models/UpdateRecordLifestatusRequestModel";
import { UpdateRecordLifestatusResponseModel } from "Core/Screens/ConsultScreen/Models/UpdateRecordLifestatusResponseModel";
import {ResizeObserver} from 'Core/Common/ResizeObserver';

import {ButtonEdit} from "Core/Controls/ButtonEdit/ButtonEdit";
import {ButtonAdd} from "Core/Controls/ButtonAdd/ButtonAdd";
import {ButtonDelete} from "Core/Controls/ButtonDelete/ButtonDelete";
import {ButtonListScreen} from "Core/Controls/ButtonListScreen/ButtonListScreen";
import {ButtonSpecialScreen} from "Core/Controls/ButtonSpecialScreen/ButtonSpecialScreen";
import {History} from "Core/Controls/History/History";
import {ButtonDashboardScreen} from "Core/Controls/ButtonDashboardScreen/ButtonDashboardScreen";
import {IScreen} from "Core/Screens/IScreen";
import {Timer} from "Core/Controls/Timer/Timer";

// Templates
import ConsultScreenTemplate
    from 'Core/Screens/Templates/AdditionalConsultScreen/ConsultScreen/ConsultScreen.html';
import ReferenceConsultScreenTemplate
    from 'Core/Screens/Templates/AdditionalConsultScreen/ConsultScreen/ReferenceConsultScreen.html';

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/ConsultScreen/ConsultScreen'] = ConsultScreenTemplate;
ko.templates['Core/Screens/Templates/AdditionalConsultScreen/ConsultScreen/ReferenceConsultScreen'] = ReferenceConsultScreenTemplate;

const ResizeService = new ResizeObserver();

export class ConsultScreen extends BaseScreen {
    private _Search: IControl;
    private _favouritesButton: IControl;
    private _pathRunner: KnockoutObservable<PathRunnerUnit>;
    private _progressBar: ProgressBar;
    private _sourceSearchTerm: KnockoutObservable<string>;
    private  _statusDescription: string;
    private _isHamburgerActive: KnockoutObservable<boolean>;
    private _areWidthsEqual: KnockoutObservable<boolean>;
    private _hideButtonCaption: KnockoutObservable<boolean>;
    private _actionBarButtonControls: IControl[]


    constructor(screenModel: ScreenModel) {
        super(screenModel, RenderModes.View);
        this._renderMode = RenderModes.View;
        this._pathRunner = ko.observable(null);

        if (!screenModel.IsNested && screenModel.Data && screenModel.IsEnablePathRunner) {
            this._pathRunner(PathRunner.Instance.GetUnit(this.GetEntityId(), screenModel.IsSpecialScreenExist));
        }

        this._progressBar = null;
        this._sourceSearchTerm = ko.observable('');
        this._isHamburgerActive = ko.observable(false);
        this._areWidthsEqual = ko.observable(false);
        this._hideButtonCaption = ko.observable(false);

        this.Init();
        this.SetData(screenModel.Data);
        this._actionBarButtonControls = [];
        if (this.ActionBar) {
            this.InitProgressBar(screenModel.Data);

            this._actionBarButtonControls = _.filter(this.ActionBar.Controls(), (control: IControl) =>
                control instanceof ButtonAdd ||
                control instanceof ButtonEdit ||
                control instanceof ButtonDelete ||
                control instanceof ButtonListScreen ||
                control instanceof ButtonSpecialScreen ||
                control instanceof History ||
                control instanceof ButtonDashboardScreen
            )

        }
        this.ApplyEvents();
    }

    IsSubjectScreen() {
        return true;
    }

     SetData(dataModel: ScreenDataModel) {
        if (!dataModel) {
            return;
        }

        if (!dataModel.Rights.IsRecordSecurityOk) {
            new Notifier().Warning(`Record security is not set for ${this.GetEntityName()} table`);
            return;
        }

        super.SetData(dataModel);
    }

    private Init() {
        this.On('SEARCH_RECORD', this, (eventArgs: any) => {
            this.SearchRecord();
        });
        this.On('EDIT_RECORD', this, (eventArgs: any) => {
            this.EditRecord();
        });
        this.On('DELETE_RECORD', this, (eventArgs: any) => {
            this.DeleteRecord();
        });
        this.On('ADD_RECORD', this, (eventArgs: any) => {
            this.CreateRecord();
        });

        this.On('SECURITY_RESET', this, (eventArgs: any) => this.SecurityReset());

        this.On('COPY_RECORD', this, (eventArgs: any) => this.OnCopy(DataModes.Copy));
        this.On('COPY_RECORD_WITH_RELATIONS', this, (eventArgs: any) => this.OnCopy(DataModes.CopyWithRelations));

        this.On('FOLLOWUP', this, (eventArgs: any) => {
            this.FollowUpFromConsultScreen();
        });

        this.On('FOLLOWUP_FROM_SUBJECT', this, (eventArgs: any) => {
            this.FollowUpFromSubject(eventArgs.data.followUpRecord, eventArgs.data.subjectAction);
        });

        this.On('MODIFY_LIFESTATUS', this, (eventArgs: any) => {
            this.ModifyLifestatus(eventArgs.data.followUpRecord);
        });
    }

    FollowUpFromConsultScreen() {
        let followUpControl = this.GetControlByType(CONTROL_TYPES.ButtonFollowUp) as ButtonFollowUp;

        let requestModel = new FollowUpRecordRequestModel();
        requestModel.EntityId = this.GetEntityId();
        requestModel.RecordId = this.GetRecordId();

        const followUpRecordModel = followUpControl.GetRecord();
        if (followUpRecordModel) {
            let actionSubject = new FollowUpActionSubjectModel();
            actionSubject.LifestatusId = followUpControl.GetRecord().LifeStatusId;
            actionSubject.ConfirmationPassword = followUpControl.GetRecord().ConfirmationPassword;

            requestModel.ActionSubject = actionSubject;
            requestModel.RetireChildren = followUpControl.GetRecord().RetireChildren;
        }

        BlockUI.Block();
        ScreenDataStore.FollowUp(requestModel)
            .always(()=>BlockUI.Unblock())
            .then((result) => {
                if (followUpRecordModel &&
                    followUpRecordModel.CurrentLifeStatus.FollowupModeName === FollowupModes.DIRECT_STATUS_UPDATE) {
                    const recordId = result.RecordId ? result.RecordId : this.GetRecordId();
                    this.LoadPathRunnerData(recordId);
                    if (this.IsInModal()) {
                        this.Close();
                    } else {
                        this.LoadData(recordId, this._model.TableTypeId);
                    }

                }else if (followUpRecordModel &&
                    followUpRecordModel.LifeStatusSort !== LIFE_STATUS_GROUPS.RETIRED &&
                    followUpRecordModel.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW) {
                    if (followUpRecordModel.LifeStatusNoActionNode) {
                        this.LoadData(this.GetRecordId(), this._model.TableTypeId);
                    } else {
                        this.NewRecord(this._model.TableTypeId,
                            this._model.KindId,
                            this.GetRecordId(),
                            null,
                            DataModes.FollowUp,
                            followUpRecordModel.LifeStatusId);
                    }
                }
            })
            .fail((error) => new Notifier().Failed(error.message));
    }

    FollowUpFromSubject(followUpRecord: FollowUpRecordModel, subjectAction: SubjectActionModel) {
        if (!followUpRecord.CurrentLifeStatus 
                ||!followUpRecord.CurrentLifeStatus.FollowupModeName 
                || followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT
                || followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT_AND_NEW) {
            this.ShowSubjectActionEditScreen(subjectAction, followUpRecord);
        } else {
            let requestModel = new FollowUpRecordRequestModel();
            requestModel.EntityId = subjectAction.EntityId;
            requestModel.RecordId = subjectAction.Id;
            requestModel.RetireChildren = followUpRecord.RetireChildren;

            let actionSubject = new FollowUpActionSubjectModel();
            actionSubject.LifestatusId = followUpRecord.LifeStatusId;
            actionSubject.ConfirmationPassword = followUpRecord.ConfirmationPassword;
            requestModel.ActionSubject = actionSubject;

            if (followUpRecord.FollowUpParentRecord) {
                let parentActionSubject = new FollowUpActionSubjectModel();
                parentActionSubject.RecordId = followUpRecord.FollowUpParentRecord.ParentActionSubject.RecordId;
                parentActionSubject.LifestatusId = followUpRecord.FollowUpParentRecord.LifeStatusId;
                parentActionSubject.ConfirmationPassword = followUpRecord.FollowUpParentRecord.ConfirmationPassword;
                requestModel.ParentActionSubject = parentActionSubject;
            }

            BlockUI.Block();
            ScreenDataStore.FollowUp(requestModel)
                .always(()=>BlockUI.Unblock())
                .then((result) => {
                    if (followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.DIRECT_STATUS_UPDATE) {
                        this.LoadData(this.GetRecordId(), this.GetTypeId());
                    } else if (followUpRecord.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW) {
                        if (followUpRecord.LifeStatusNoActionNode || followUpRecord.LifeStatusSort === LIFE_STATUS_GROUPS.RETIRED) {
                            this.LoadData(this.GetRecordId(), this._model.TableTypeId);
                        } else {
                            this.NewSubjectActionRecord(subjectAction.EntityId,
                                subjectAction.TypeId,
                                subjectAction.KindId,
                                subjectAction.Id,
                                DataModes.FollowUp,
                                followUpRecord.LifeStatusId);
                        }
                    }
                })
                .fail((error) => new Notifier().Failed(error.message));
        }
    }

    ModifyLifestatus(followUpRecord: FollowUpRecordModel) {
        let requestModel = new UpdateRecordLifestatusRequestModel();
        requestModel.EntityId = this.GetEntityId();
        requestModel.RecordId = this.GetRecordId();
        requestModel.LifestatusId = followUpRecord.LifeStatusId;

        ScreenDataStore.UpdateRecordLifestatus(requestModel)
            .then((result) => {
                this.LoadData(this.GetRecordId(), this.GetTypeId());
            })
            .fail((error) => new Notifier().Failed(error.message));
    }

    private OnCopy(dataMode: DataModes) {
        this.IsTypeAvailable(this.GetTableTypeId(), this.GetEntityId())
            .then(() => this.NewRecord(this.GetTableTypeId(), this.GetKindId(), this.GetRecordId(), null, dataMode));
    }

    private InitProgressBar(data: ScreenDataModel) {
        if (data && data.RecordSpecs && data.RecordSpecs.LifeStatusInfo) {
            this._progressBar = new ProgressBar({
                EntityId: this.GetEntityId(),
                RecordId: this.GetRecordId(),
                TypeId: this.GetTableTypeId(),
                Screen: this,
                RenderMode: this._renderMode,
                RecordSpecs: data.RecordSpecs
            });

            this._statusDescription = data.RecordSpecs.LifeStatusInfo.MemoTranslation || data.RecordSpecs.LifeStatusInfo.Memo;
        }
    }

    private ApplyEvents() {
        _.each(this._controls, control => {
            if (control.GetType() === CONTROL_TYPES.Drop) {
                control.On(DROP_EVENTS.DOCUMENT_UPLOADED, this, (eventArgs) =>
                    this.Trigger(CONSULT_SCREEN_EVENTS.REFRESH_GRID, {EntityId: eventArgs.data.EntityId}));
            }
        });
    }

    private ReleaseLockOnWindowClose = () => {
        LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
        window.removeEventListener('beforeunload', this.ReleaseLockOnWindowClose);
    };

    private HandleOnWindowClose() {
        window.addEventListener('beforeunload', this.ReleaseLockOnWindowClose);
    }

    private RemoveOnWindowCloseHandler() {
        window.removeEventListener('beforeunload', this.ReleaseLockOnWindowClose);
    }

    SecurityReset() {
        RecordSecurityStore.ResetSecurity(this.GetRecordId())
            .then(() => {
                new Notifier().Success(NOTIFICATIONS.SECURITY_RESET_DONE);
                PubSub.publish(PUB_SUB_EVENTS.RELOAD_SCREEN, null);
                PubSub.publish(PUB_SUB_EVENTS.REFRESH_MAIN_MENU, null);
            })
            .fail((error) => new Notifier().Failed(error.message));
    }

    GetTemplateName() {
        return 'Core/Screens/Templates/AdditionalConsultScreen/ConsultScreen/ConsultScreen';
    }

    GetReferenceConsultScreenTemplate() {
        return 'Core/Screens/Templates/AdditionalConsultScreen/ConsultScreen/ReferenceConsultScreen';
    }

    SearchRecord(): void {
        const SearchControl = this.GetTextSearchTermControl() as Search;
        const searchTerm = SearchControl ? SearchControl.GetValue() : '';
        const conditionToggler = SearchControl ? SearchControl.HasConditions() : false;
        const searchScreen = new SearchScreen({
            EntityId: this.GetEntityId(),
            SearchTerm: searchTerm,
            ControlId: SearchControl != null ? SearchControl.GetControlId() : 0,
            ConditionToggler: conditionToggler
        });

        searchScreen.On('RECORD_SELECTED', this, (eventArgs) => {
            this._sourceSearchTerm(eventArgs.data.SearchTerm);
            var recordId = eventArgs.data.RecordId;
            var typeId = eventArgs.data.TypeId;
            UserVarsManager.Instance.AddRecent(this.GetEntityId(), recordId, typeId);
            this.LoadData(recordId, typeId);
        });


        searchScreen.On('ALT_ENTITY_RECORD_SELECTED', this, (eventArgs) => {
            const data = eventArgs.data;
            UserVarsManager.Instance.AddRecent(data.EntityId, data.RecordId, data.TypeId);
            data.IsOpenInModal = this.IsInModal();
            PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
        });


        searchScreen.On('NEW_RECORD', this, (eventArgs) => {
            const showTypeSelector = (allowChildrenTypes: boolean) => {
                const parentTypeId = allowChildrenTypes ? this.GetTableTypeId() : 0;
                const typeScreen = new TypeScreen(this.GetEntityId(), parentTypeId, true);

                typeScreen
                    .On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND))
                    .On('TYPE_SELECTED', this, (eventArgs) => {
                        const typeId = eventArgs.data.TypeId;
                        const kindId = eventArgs.data.KindId;
                        const exampleRecordId = eventArgs.data.ExampleRecordId;

                        searchScreen.Cancel();
                        this.NewRecord(typeId, kindId, exampleRecordId, searchScreen.SearchTerm());
                    });

                typeScreen.Show();
            }

            if (this.IsInModal()) {
                showTypeSelector(true);
                return;
            }

            const profileSelectorScreen = new ProfileSelectorScreen(this.GetEntityId(), this.GetEntityName());

            profileSelectorScreen
                .On('PROFILES_NOT_FOUND', this, () => {
                    new Notifier().Warning('Profiles with create possibilities not found');
                })
                .On('PROFILE_SELECTION_CANCELLED', this, () => {
                    profileSelectorScreen.Close();
                })
                .On('PROFILE_SELECTED', this, () => {
                    profileSelectorScreen.Close();
                    searchScreen.Cancel();
                    showTypeSelector(false);
                })
                .On('USED_CURRENT_PROFILE', this, () => {
                    profileSelectorScreen.Close();
                    showTypeSelector(true);
                });

            profileSelectorScreen.ShowIfNeeded();
        });

        searchScreen.Show();
    }

    LoadData(recordId: number, tableTypeId: number, closeScreen: boolean = false) {
        if (closeScreen) {
            this.Close();
        }

        return this.LoadScreenFor(tableTypeId, recordId);
    }

    Refresh() {
        this.LoadPathRunnerData(this.GetRecordId());
        if(this.IsReferenceScreen){
            this.Trigger('REFRESH_REFERENCE_SCREEN');
        }else{
            this.LoadData(this.GetRecordId(), this.GetTableTypeId());
        }        
    }

    ProhibitionAutoStopTimerForCurrentScreen(currentScreen: IScreen, newScreen: IScreen): void {
        if (currentScreen && (newScreen.GetRecordId() === currentScreen.GetRecordId()) ) {

            let timerControl: IControl = currentScreen.GetControl('Timer');
            if (timerControl instanceof Timer) {
                if (timerControl.TimerAutostart && timerControl.HasTimerStarted()) {
                    currentScreen.AllowTimerAutoStop = false;
                }
            }
        }
    }

    LoadScreenFor(tableTypeId: number, recordId: number): Promise<any> {

        BlockUI.Block();
        return new Promise(async (resolve, reject) => {
            const screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
            
            screenManager.GetScreenByScreenType(this.GetEntityId(), ScreenTypes.ConsultScreen, recordId)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(async screen => {
                    if(screen.ScreenDoesNotExistError){
                        Notifier.Failed(screen.ScreenDoesNotExistErrorMessage);
                        return;
                    }
                    this.Close();
                    screen.SetIsReady(true);

                    this.ProhibitionAutoStopTimerForCurrentScreen(this, screen);

                    const menuManager = (await import ("MenuManager/MenuManager")).MenuManager;
                    menuManager.Instance.GoToScreen(screen, this.IsInModal());
                    menuManager.Instance.SelectedScreen(screen, this.IsInModal());
                    var baseScreen = screen as BaseScreen;

                    var textSearchTerm = baseScreen.GetControl<Search>(CONTROL_TYPES.Search);
                    if (textSearchTerm) {
                        textSearchTerm.SetSearchTerm(this._sourceSearchTerm());
                    }

                    if (screen.GetType() === ScreenTypes[ScreenTypes.ConsultScreen]) {
                        this.LoadPathRunnerData(screen.GetRecordId()).always(() => resolve(null))
                    } else {
                        resolve(null);
                    }

                    PubSub.publish(PUB_SUB_EVENTS.CHANGE_SCREEN, screen);
                })
                .fail(err => new Notifier().Warning(err.message));
        });
    }

    GetTextSearchTermControl(): IControl {
        return _.first(_.filter(this.GetAllControls(), (control: IControl) => control.GetType() === CONTROL_TYPES.Search));
    }

    DeleteRecord(): void {
        const notifier = new Notifier($(this._el));

        if (!this.GetRecordId()) {
            notifier.Warning(NOTIFICATIONS.PLEASE_SELECT_ANY_RECORD);
            return;
        }

        this.HandleOnWindowClose();
        BlockUI.Block();

        LockManager.Instance.TryLock(this.GetEntityId(), this.GetRecordId())
            .then(() => {
				if (!this.IsEntitySysUsersOrCd()) {
					RecordStore.GetDeletionMode({ EntityId: this.GetEntityId(), RecordId: this.GetRecordId() })
						.always(() => {
							BlockUI.Unblock();
						})
						.then(result => {
							if (!result.IsSuccessfull) {
								notifier.Failed(result.ErrorMessage || NOTIFICATIONS.ERROR_DELETING_RECORD);
								LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
								this.RemoveOnWindowCloseHandler();
								return;
							}

							const deletionMode = result.ResultObject;

							if (deletionMode.OnDelete || deletionMode.OnDelete === 0) {
								this.ShowDeletionModeDialog(deletionMode);
							} else {
								this.ShowConfirmationDialog();
							}
						});
				} else {
					this.ShowConfirmationDialog();
				}
            })
            .fail(() => this.RemoveOnWindowCloseHandler())
            .always(() => BlockUI.Unblock());
    }

    private ShowDeletionModeDialog(deletionModeModel: DeletionModeModel) {
	    const deletionModeDialog = new DeletionModeDialog({
		    DeletionMode: deletionModeModel.OnDelete,
		    HasCustomLifeStatus: deletionModeModel.HasCustomLifeStatus
	    });

	    deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DELETE_SELECTED, this, () => {
		    this.UseDeleteDeletionMode();
	    });

	    deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DISABLE_SELECTED, this, () => {
		    this.UseDisableDeletionMode();
	    });

	    deletionModeDialog.On(DELETION_MODE_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
		    LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
		    this.RemoveOnWindowCloseHandler();
	    });

	    deletionModeDialog.Show();
    }

	private ShowConfirmationDialog() {
		const confirmationDialog = new ConfirmationDialog({
			Text: this.DeleteRecordConfirmation,
			Type: ConfirmationTypes.Question
		});

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
			this.UseDeleteDeletionMode();
		});

		confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
			LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
			this.RemoveOnWindowCloseHandler();
		});

		confirmationDialog.Show();
	}

	private UseDeleteDeletionMode() {
		BlockUI.Block();

		const notifier = new Notifier();
		RecordStore.DeleteRecord({ EntityId: this.GetEntityId(), RecordId: this.GetRecordId() })
			.always(() => {
				LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
				this.RemoveOnWindowCloseHandler();
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					notifier.Failed(result.ErrorMessage || NOTIFICATIONS.ERROR_DELETING_RECORD);
					return;
				}

				if (result.Warnings && result.Warnings.length > 0) {
					_.each(result.Warnings, (warning) => notifier.Warning(warning));

					notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
					this.LoadData(this.GetRecordId(), this.GetTableTypeId());
				} else {
					notifier.Success(this.DeleteRecordSuccessNotification);
					this.DeleteFromRecent();
				}
			})
			.fail(error => {
				notifier.Warning(error.message);
			});
	}

	private UseDisableDeletionMode() {
		BlockUI.Block();

		const notifier = new Notifier($(this._el));
		RecordStore.DeleteRecord({ EntityId: this.GetEntityId(), RecordId: this.GetRecordId(), Disable: true })
			.always(() => {
				LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
				this.RemoveOnWindowCloseHandler();
				BlockUI.Unblock();
			})
			.then(result => {
				if (!result.IsSuccessfull) {
					notifier.Failed(result.ErrorMessage);
					return;
				}

				notifier.Success(NOTIFICATIONS.RECORD_UPDATED);
				this.LoadData(this.GetRecordId(), this.GetTableTypeId());
			})
			.fail(error => {
				notifier.Warning(error.message);
			});
	}

    async NewRecord(tableTypeId: number, kindId: number, exampleRecordId: number, searchTerm?: string,
              dataMode: DataModes = DataModes.Default, subjectLifestatusId: number = null) {
        BlockUI.Block();

        const screenManager = (await import ('Core/ScreenManager/ScreenManager')).ScreenManager;
        screenManager.GetEditScreen({
            EntityId: this.GetEntityId(),
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
            DataMode: dataMode,
            SubjectLifestatusId: subjectLifestatusId,
            ParentRecordId: this.GetRecordId()
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = this.GetRecordId();
                editScreen.UseLinking = true;
                editScreen.NewlyCreatedRecord = true;

                if (searchTerm) {
                    this.NameControlSetValue(editScreen, searchTerm);
                }

                screen
                    .On('RECORD_SAVED', this, (eventArgs) => {
                        const notifier = new Notifier();
                        notifier.Success(NOTIFICATIONS.RECORD_CREATED);

                        UserVarsManager.Instance.AddRecent(this.GetEntityId(), eventArgs.data.RecordId, tableTypeId);
                        this.LoadPathRunnerData(eventArgs.data.RecordId);

                        if (this.IsNested()) {
                            this.Trigger(CONSULT_SCREEN_EVENTS.LINK_BOOK_RECORD, {EntityId: this.GetEntityId(), RecordId: eventArgs.data.RecordId});
                        }
                        else if (this.IsInModal()) {
                            this.Close();
                        } else {
                            this.LoadData(eventArgs.data.RecordId, tableTypeId);
                        }
                    })
                    .On('COPY', this, (eventArgs) => {
                        if (this.IsInModal()) {
                            this.Close();
                        }

                        this.IsTypeAvailable(this._model.TableTypeId, this._model.EntityId)
                            .then(() => this.NewRecord(this._model.TableTypeId, null, eventArgs.data.recordId, null, eventArgs.data.dataMode));
                    });

                screen.ShowInModal();
            })
            .fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
    }

    async NewSubjectActionRecord(entityId: number, tableTypeId: number, kindId: number, exampleRecordId: number,
        dataMode: DataModes = DataModes.Default, subjectLifestatusId: number = null) {
        BlockUI.Block();
        const screenManager = (await import ('Core/ScreenManager/ScreenManager')).ScreenManager;

        screenManager.GetEditScreen({
            EntityId: entityId,
            TableTypeId: tableTypeId,
            KindId: kindId,
            RecordId: exampleRecordId,
            LoadAsExample: exampleRecordId > 0,
            DataMode: dataMode,
            SubjectLifestatusId: subjectLifestatusId
        })
            .always(() => {
                BlockUI.Unblock();
            })
            .then((screen: EditScreen) => {
                const editScreen = screen;

                editScreen.IsDataFromExample = exampleRecordId > 0;
                editScreen.ParentRecordId = exampleRecordId;
                editScreen.UseLinking = true;

                screen
                    .On('RECORD_SAVED', this, (eventArgs) => {
                        const notifier = new Notifier($(this._el));
                        notifier.Success(NOTIFICATIONS.RECORD_CREATED);

                        if (this.IsInModal()) {
                            this.Close();
                        } else {
                            this.LoadData(this.GetRecordId(), this.GetTableTypeId());
                        }
                    })
                    .On('COPY', this, (eventArgs) => {
                        if (this.IsInModal()) {
                            this.Close();
                        }

                        this.IsTypeAvailable(tableTypeId, entityId)
                            .then(() => this.NewSubjectActionRecord(entityId, tableTypeId, kindId, eventArgs.data.recordId, eventArgs.data.dataMode));
                    });

                screen.ShowInModal();
            })
            .fail(error => {
                new Notifier($(this._el)).Warning(error.message);
            });
    }

    EditRecord() {
        if (!this.GetRecordId()) {
            const notifier = new Notifier($(this._el));
            notifier.Warning(NOTIFICATIONS.PLEASE_SELECT_ANY_RECORD);
            return;
        }


        if (this._dataModel.IsTypeTransformationRequired) {
            const typeScreen = new TypeScreen(this.GetEntityId(), this.GetTableTypeId(), true, false);

            typeScreen.On("TYPE_SELECTED",
                this,
                (eventArgs) => {
                    UserVarsManager.Instance.RemoveFromRecent(this.GetRecordId(), this._model.EntityId, this.GetTableTypeId());

                    const typeId = eventArgs.data.TypeId;
                    const kindId = eventArgs.data.KindId;

                    UserVarsManager.Instance.AddRecent(this.GetEntityId(), this.GetRecordId(), typeId);
                    this.ShowEditScreen(typeId, kindId);
                })
                .On('TYPES_NOT_FOUND', this, (eventArgs) => {
                    new Notifier($(this._el)).Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
                });

            typeScreen.Show();
        } else {
            this.ShowEditScreen();
        }
    }

    CreateRecord() {
        const showTypeSelector = (allowChildrenTypes: boolean) => {
            const SearchControl = this.GetTextSearchTermControl();
            const searchTerm = SearchControl ? SearchControl.GetValue() : '';

            const parentTypeId = allowChildrenTypes ? this.GetTableTypeId() : 0;
            const typeScreen = new TypeScreen(this.GetEntityId(), parentTypeId, true);

            typeScreen
                .On('TYPES_NOT_FOUND', this, (eventArgs) => {
                    new Notifier().Warning(eventArgs.data.Message || NOTIFICATIONS.TYPES_NOT_FOUND);
                })
                .On('TYPE_SELECTED', this, (eventArgs) => {
                    const typeId = eventArgs.data.TypeId;
                    const kindId = eventArgs.data.KindId;
                    const exampleRecordId = eventArgs.data.ExampleRecordId;

                    this.NewRecord(typeId, kindId, exampleRecordId, searchTerm);
                });

            typeScreen.Show();
        };

        if (this.IsInModal()) {
            showTypeSelector(true);
            return;
        }

		const profileSelectorScreen = new ProfileSelectorScreen(this.GetEntityId(), this.GetEntityName());

        profileSelectorScreen
            .On('PROFILES_NOT_FOUND', this, () => {
                new Notifier().Warning('Profiles with create possibilities not found');
            })
            .On('PROFILE_SELECTION_CANCELLED', this, () => {
                profileSelectorScreen.Close();
            })
            .On('PROFILE_SELECTED', this, () => {
                profileSelectorScreen.Close();
                showTypeSelector(false);
            })
            .On('USED_CURRENT_PROFILE', this, () => {
                profileSelectorScreen.Close();
                showTypeSelector(true);
            });

        profileSelectorScreen.ShowIfNeeded();
    }

    ShowEditScreen(newTypeId?: number, kindId?: number) {
        BlockUI.Block();

        LockManager.Instance.TryLock(this.GetEntityId(), this.GetRecordId())
            .then(async () => {
                const screenManager = (await import ('Core/ScreenManager/ScreenManager')).ScreenManager;
                screenManager.GetEditScreen({
                    EntityId: this.GetEntityId(),
                    TableTypeId: newTypeId ? newTypeId : this.GetTableTypeId(),
                    KindId: kindId,
                    RecordId: this.GetRecordId(),
                    DataMode: DataModes.Default
                })
                    .then((screen: EditScreen) => {

                        LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs: any) => {
                            if (eventArgs.data.TableId === this.GetEntityId() && eventArgs.data.RecordId === this.GetRecordId()) {
                                screen.Close();
                            }
                        });

                        screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId()));

                        screen.On('RECORD_SAVED', this, (eventArgs) => {
                            if (newTypeId) {
                                this._model.TableTypeId = newTypeId;
                            }

                            const notifier = new Notifier();

                            notifier.Success(NOTIFICATIONS.RECORD_UPDATED);

                            this.LoadPathRunnerData(this.GetRecordId());

                            if (this.IsInModal()) {
                                this.Close();
                                if (!eventArgs.data.IsFollowUp) {
                                    this.Trigger('RECORD_SAVED');
                                }
                            } else {
                                this.LoadData(this.GetRecordId(), this.GetTableTypeId());
                            }
                        });

                        screen.On('RECORD_REMOVED', this, (eventArgs) => {
                            const notifier = new Notifier($(this._el));

                            notifier.Success(screen.DeleteRecordSuccessNotification);
                            this.LoadData(null, null, this.IsInModal());
                            this.ClearPathRunner();
                        });

                        screen.On('FOLLOWUP_RECORD', this, (eventArgs) => this.OnFollowUpRecordFromEditScreen(eventArgs));

                        screen.On('COPY', this, (eventArgs) => {
                            this.IsTypeAvailable(this._model.TableTypeId, this._model.EntityId)
                                .then(() => this.NewRecord(this._model.TableTypeId, this.GetKindId(), eventArgs.data.recordId, null, eventArgs.data.dataMode));
                        });

                        screen.ShowInModal();

                    })
                    .fail(err => {
                        LockManager.Instance.ReleaseLock(this.GetEntityId(), this.GetRecordId());
                        let notifier = new Notifier($(this._el));
                        notifier.Warning(err.message);
                    })
                    .always(() => {
                        BlockUI.Unblock();
                    });
            })
            .fail(err => BlockUI.Unblock());
    }

    ShowSubjectActionEditScreen(subjectAction: SubjectActionModel, followUpRecord: FollowUpRecordModel) {
        BlockUI.Block();

        LockManager.Instance.TryLock(subjectAction.EntityId, subjectAction.Id)
            .then(async () => {
                const screenManager = (await import ('Core/ScreenManager/ScreenManager')).ScreenManager;
                screenManager.GetEditScreen({
                    EntityId: subjectAction.EntityId,
                    TableTypeId: subjectAction.TypeId,
                    KindId: subjectAction.KindId,
                    RecordId: subjectAction.Id,
                    DataMode: DataModes.Default
                })
                    .then((screen: EditScreen) => {
                        let followUpControl = screen.GetControlByType(CONTROL_TYPES.ButtonFollowUp) as ButtonFollowUp;
                        followUpControl.SetRecord(followUpRecord);

                        LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs: any) => {
                            if (eventArgs.data.TableId === subjectAction.EntityId && eventArgs.data.RecordId === subjectAction.Id) {
                                screen.Close();
                            }
                        });

                        screen.On('MODAL_CLOSE', this, () => LockManager.Instance.ReleaseLock(subjectAction.EntityId, subjectAction.Id));

                        screen.On('RECORD_SAVED', this, (eventArgs) => {
                            const notifier = new Notifier($(this._el));

                            notifier.Success(NOTIFICATIONS.RECORD_UPDATED);

                            if (this.IsInModal()) {
                                this.Close();
                                if (!eventArgs.data.IsFollowUp) {
                                    this.Trigger('RECORD_SAVED');
                                }
                            } else {
                                this.LoadData(this.GetRecordId(), this.GetTableTypeId());
                            }
                        });

                        screen.On('RECORD_REMOVED', this, (eventArgs) => {
                            const notifier = new Notifier($(this._el));
                            notifier.Success(screen.DeleteRecordSuccessNotification);
                            this.LoadData(this.GetRecordId(), this.GetTableTypeId());
                        });

                        screen.On('FOLLOWUP_RECORD', this, (eventArgs) => this.OnFollowUpSubjectActionRecordFromEditScreen(subjectAction.EntityId, subjectAction.TypeId, subjectAction.KindId, eventArgs));

                        screen.On('COPY', this, (eventArgs) => {
                            this.IsTypeAvailable(subjectAction.TypeId, subjectAction.EntityId)
                                .then(() => this.NewSubjectActionRecord(subjectAction.EntityId, subjectAction.TypeId, subjectAction.KindId, eventArgs.data.recordId, eventArgs.data.dataMode));
                        });

                        screen.ShowInModal();

                    })
                    .fail(err => {
                        LockManager.Instance.ReleaseLock(subjectAction.EntityId, subjectAction.Id);
                        let notifier = new Notifier($(this._el));
                        notifier.Warning(err.message);
                    })
                    .always(() => {
                        BlockUI.Unblock();
                    });
            })
            .fail(err => BlockUI.Unblock());
    }

    OnFollowUpSubjectActionRecordFromEditScreen(entityId: number, typeId: number, kindId: number, eventArgs: EventArgs) {
       const followUp = eventArgs.data.followUpRecordModel as FollowUpRecordModel;
       
       if (!followUp) {
           this.NewSubjectActionRecord(entityId, typeId, kindId, eventArgs.data.RecordId, DataModes.FollowUp);
       } else if (followUp.LifeStatusSort !== null
           && followUp.LifeStatusSort !== LIFE_STATUS_GROUPS.RETIRED
           && !followUp.LifeStatusNoActionNode) {
           if (!followUp.CurrentLifeStatus.FollowupModeName
               || followUp.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT_AND_NEW
               || followUp.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW) {
               this.NewSubjectActionRecord(entityId, typeId, kindId, eventArgs.data.recordId,DataModes.FollowUp, followUp.LifeStatusId);
           } else {
               this.LoadData(this.GetRecordId(), this._model.TableTypeId);
           }
       }
    }

    OnFollowUpRecordFromEditScreen(eventArgs: EventArgs) {
        const followUp = eventArgs.data.followUpRecordModel as FollowUpRecordModel;
        if (!followUp) {
            this.NewRecord(this._model.TableTypeId, this._model.KindId, eventArgs.data.recordId, null, DataModes.FollowUp);
        } else if (followUp.LifeStatusSort !== null
            && followUp.LifeStatusSort !== LIFE_STATUS_GROUPS.RETIRED
            && !followUp.LifeStatusNoActionNode) {
            if (!followUp.CurrentLifeStatus.FollowupModeName
                || followUp.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_CURRENT_AND_NEW
                || followUp.CurrentLifeStatus.FollowupModeName === FollowupModes.EDIT_NEW) {
                this.NewRecord(this._model.TableTypeId,
                    this._model.KindId,
                    eventArgs.data.recordId,
                    null,
                    DataModes.FollowUp,
                    followUp.LifeStatusId);
            } else {
                this.LoadData(eventArgs.data.followUpRecordId, this._model.TableTypeId);
            }
        }else{
            if (!this.IsInModal()) {
                this.LoadData(null, null);
            }

            this.ClearPathRunner();
        }
    }

    SetRecordId(recordId: number): void {
        this._recordId = recordId;
    }

    AfterRender(el) {
        super.AfterRender(el);
        this.SetScreenStyle(false, this.IsOpenedViaBookControl);

        const unbindResize = ResizeService.SubscribeWidth(this.OnResize.bind(this, el), el[0]); // subscribe on resize

        ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
            unbindResize();
        });
        this.CheckHideButtonCaption(el);
        // this.ToggleActionBar(el);
    }

    OnResize = (el) => {
        this.CheckHideButtonCaption(el);
        // this.ToggleActionBar(el);
    };

    CheckHideButtonCaption(el): void {
        const actionBarWrapper = $(el).find('.action-bar-wrapper');
        if (actionBarWrapper.length) {
            const actionBar_barLeft = $('.bar-left', actionBarWrapper).width();
            const actionBar_controls = $('.actionBar-Controls', actionBarWrapper).width();
            this._hideButtonCaption(actionBar_barLeft === actionBar_controls);

            _.each(this._actionBarButtonControls, (control: IControl)=> {
                control.SetHideCaption(this._hideButtonCaption());
            })
        }
    }

    // ToggleActionBar(el){
    //     const actionBarWrapper = $(el).find('.action-bar-wrapper');
    //     if (actionBarWrapper.length) {
    //         const actionBar_barLeft = $('.bar-left', actionBarWrapper).width();
    //         const actionBar_controls = $('.actionBar-Controls', actionBarWrapper).width();
    //         this._areWidthsEqual(actionBar_barLeft === actionBar_controls);
    //     }
    // }

    ToggleHamburger(data, event){
        $(event.target).find('.ham').toggleClass('active')
        this._isHamburgerActive(!this._isHamburgerActive());


        // this._hideButtonCaption(!this._hideButtonCaption());
        // _.each(this._actionBarButtonControls, (control: IControl)=> {
        //     control.SetHideCaption(this._hideButtonCaption());
        // })
    }

    ToggleLifeStatusBarClass(data, event){
        if ($(window).width() <= 991){
            $(event.target).parents('.lifestatus-bar-wrapper').toggleClass('showLifeStatus');
        }
    }

    get ScreenSwitcher(): boolean {
        return this._screenSwitcher() && this._screenSwitcher().GetEnabled();
    }

    GridRowDataDeleteCurrentRow() {
        let currentRow = this._screenSwitcher().GridRowData.indexOf(this._screenSwitcher().CurrentRow);
        this._screenSwitcher().GridRowData.splice(currentRow, 1);
    }

    private DeleteFromRecent() {
        UserVarsManager.Instance.RemoveFromRecent(this.GetRecordId(), this._model.EntityId, this._model.TableTypeId);

        const lastRecentRecord = UserVarsManager.Instance.GetLastRecentRecordByEntityId(this._model.EntityId);

        if (!lastRecentRecord) {
            this.LoadScreenFor(0, 0);

            return;
        }

        if (this._screenSwitcher()) {
            if (this._screenSwitcher().GridRowData.length > 1) {

                this.GridRowDataDeleteCurrentRow();

                if (this._screenSwitcher().NextScreen) {
                    this._screenSwitcher().GetNextScreen();
                } else {
                    this._screenSwitcher().GetPreviousScreen();
                }
            } else {
                this.GridRowDataDeleteCurrentRow();
                this.Close();
            }

        } else {
            this.LoadData(lastRecentRecord.id, lastRecentRecord.typeId);
            this.LoadPathRunnerData(lastRecentRecord.id);
        }
    }

    private NameControlSetValue(screen: EditScreen, value: string) {
        const control = screen.GetControlByFieldName('NAME', CONTROL_TYPES.Text);

        if (control) {
            const controlData = new ControlDataModel();

            controlData.FieldId = control.FieldModel.Id;
            controlData.Value = value;
            controlData.DisplayValue = value;

            const controlValue: IControlValue = {
                Data: controlData,
                SubjectEntityId: this._model.EntityId,
                SubjectRecordId: this._recordId,
                RecordSpecsModel: new RecordSpecsModel()
            };

            control.SetDefaultValue(controlValue);
        }
    }

    private LoadPathRunnerData(recordId: number) {
        if (!this._pathRunner()) {
            return P.resolve(null);
        }

        return this._pathRunner().LoadNewData(recordId);
    }

    get IsPathRunner(): boolean {
        return this._pathRunner() && this._pathRunner().IsPathRunner();
    }

    get HasSpecialScreen(): boolean {
        return this._pathRunner() && this._pathRunner().IsSpecialScreenExist();
    }

    private ClearPathRunner() {
        this._pathRunner() && this._pathRunner().Clear();
    }
}