import * as ko from 'knockout';
import {deserialize} from 'libs/cerialize';
import {deserializeAs} from 'libs/cerialize';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel';
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';
import {Icon} from 'Core/Icon/Icon';

import {TriggerModel} from 'Core/Controls/BaseControl/Models/TriggerModel';

export class ControlModel {
    private _isInitializedControl: boolean;

    constructor(init?: Partial<ControlModel>) {
        this.Fields = [];
        this.SubControls = [];
        this.NameTranslations = [];
        this.DescriptionTranslations = [];
        this.Sort = 0;
        this.Triggers = [];
        this._isInitializedControl = true;
        Object.assign(this, init);
    }

    SetInitializedControl(value: boolean){
        this._isInitializedControl = value;
    }
    get InitializedControl(): boolean {
        return this._isInitializedControl;
    }

    @deserialize
    Id: number;

    @deserialize
    TypeName: string;

    @deserialize
    TypeTranslatedName: string; 

    @deserialize
    TypeId: number;

    @deserialize
    Name: string;

    @deserialize
    NameMaxSize: number;

    @deserialize
    FieldNameTranslation;

    @deserializeAs(Number)
    ToolBars: Array<Number>;

    @deserialize
    Label: string;

    @deserialize
    TranslatedName: string;

    @deserialize
    EntityId: number;

    @deserialize
    EntityName: string;

    @deserialize
    EntityTypeName: string;

    SubTableId: number;

    @deserialize
    LabelPosition: string;

    @deserialize
    LabelPositionValue: number;

    @deserialize
    Properties: any;

    @deserializeAs(ControlModel)
    SubControls: Array<ControlModel>;

    @deserializeAs(AttachedFieldModel)
    Fields: Array<AttachedFieldModel>;

    @deserializeAs(TranslationModel)
    NameTranslations: Array<TranslationModel>;

    @deserializeAs(TranslationModel)
    DescriptionTranslations: Array<TranslationModel>;

    @deserialize
    IsParentControl: boolean;

    @deserialize
    IsFieldAttached: boolean;

    @deserialize
    IsParentForFields: boolean;

    @deserialize
    IsBlobFieldAttached: boolean;

    @deserialize
    IsLookupFieldAttached: boolean;

    @deserialize
    IsMultiSelectFieldAttached: boolean;

    @deserialize
    IsComplexControl: boolean;

    @deserialize
    IsColorFieldAttached: boolean;

    @deserialize
    IsReadOnly: boolean;

    @deserialize
    IsRequired: boolean;

    @deserialize
    Predefined: boolean;

    @deserialize
    Sort: number;

    @deserialize
    Description: string;

    @deserialize
    ExampleAlias: string;

    @deserialize
    FieldFlag: number;

    @deserialize
    IsMailEnabled: boolean;

    @deserializeAs(TriggerModel)
    Triggers: Array<TriggerModel>;

    @deserialize
    HasSpreadsheetData: boolean;

    @deserializeAs(IconModel)
    Icon: IconModel;

    @deserialize
    RecordLifeStatusId: number;

    @deserialize
    DestinationTypeId: number;

    @deserialize
    UseFieldName: boolean;


    @deserialize
    IsDisabledByCondition: boolean;

    @deserialize
    IsAutoStartByCondition: boolean;

    @deserialize
    IsLinkedDocumentsAmountLimitReached: boolean;

    @deserialize
    TitleFieldId: number;

    @deserialize
    KanbanSubjectId: number;

    @deserialize
    TodoEntityId: number;

    @deserialize
    TodoTypeId: number;

    @deserialize
    ScreenId: number;

    @deserialize
    TodoTypeName: string;

    @deserialize
    TodoFieldId: number;

	ResetDependsOnValue: boolean;

	SkipTimeShifting: boolean;

    SetBackgroundColor(color: string) {
        if (!this.Properties) {
            this.Properties = {};
        }

        this.Properties.BackgroundColor = color;
    }

    static OnDeserialized(instance: ControlModel, json: any): void {
        try {
            if (json.Properties) {
                instance.Properties = JSON.parse(json.Properties);
            }
        } catch (err) {
        }
    }
}