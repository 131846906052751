import { RightBarCell } from 'Core/Controls/PlannerLevel/RightBarCell/RightBarCell';
import * as ko from 'knockout';
import * as _ from "underscore";

import {RenderModes, EVENTS} from 'Core/Constant'
import {P} from 'Core/Common/Promise';
import {TranslationManager} from "Core/Components/Translation/TranslationManager";

import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {IControl} from 'Core/Controls/IControl';
import {IControlParam, IScreen} from 'Core/Screens/IScreen'

import ViewTemplate from 'Core/Controls/StepPage/Templates/View.html'
import HelpViewTemplate from 'Core/Controls/StepPage/Templates/HelpView.html'
import DesignTemplate from 'Core/Controls/StepPage/Templates/Design.html'
import type { ProcessCardPage } from '../../Screens/ProcessCardPage/ProcessCardPage';
import { BlockUI } from '../../Common/BlockUi';
import { Notifier } from '../Grid/BaseGrid/Index';
import { StepApi } from '../Step/Stores/StepApi';
import {LOCK_EVENTS, LockManager} from "Core/Components/Locker/LockManager";

ko.templates['Core/Controls/StepPage/Templates/Design'] = DesignTemplate;
ko.templates['Core/Controls/StepPage/Templates/View'] = ViewTemplate;
ko.templates['Core/Controls/StepPage/Templates/HelpView'] = HelpViewTemplate;
ko.templates['Core/Controls/StepPage/Templates/Edit'] = ViewTemplate;

export class StepPage extends BaseControl {
	
	private _screen: KnockoutObservable<ProcessCardPage>;
	StepRecordId: number;
	StepEntityId: number;
	IsNewRecord: boolean;
	private _index: number;
	protected _process: KnockoutObservable<boolean>;
	protected _wait: KnockoutObservable<boolean>;
	protected _finish: KnockoutObservable<boolean>;
	private _transactionId: string;

	constructor(params: IControlParam) {
		super(params);
		this.StepRecordId = 0;
		this._screen = ko.observable(null);
		this._index = null;
		this._process = ko.observable(null);
		this._wait = ko.observable(null);
		this._finish = ko.observable(null);

		this.Init();

		this.GetTemplateName = ko.computed(() => {
			var template = RenderModes[this._renderMode()];
			var templateName = `Core/Controls/StepPage/Templates/${template}`;
			return templateName;
		});

	}

	ApplyProperties() {	}

	private Init(): void {
		this._isActive = ko.observable(false);
		this.AddEvent(EVENTS.ON_TAB_CLICK);
	}

	LoadRecord({entityId, recordId, transactionId}: {entityId: number, recordId: number, transactionId: string}){
		this._transactionId = transactionId;
		if(this.StepRecordId == 0){
			BlockUI.Block();
			StepApi.GetStepRecord({PreviousStepEntityId: entityId, PreviousStepRecordId: recordId, StepScreenId: this.ScreenId })
			.always(()=>{
				BlockUI.Unblock();
			})
			.then((record)=>{
				this.StepRecordId = record.RecordId ? record.RecordId : 0;
				this.StepEntityId = record.EntityId;
				this.LoadScreen(this._transactionId);
			});	
		}else{
			this.LoadScreen(this._transactionId);
		}
	}

	async LoadScreen(transactionId: string){
		this._transactionId = transactionId;
		BlockUI.Block();

		let screenManager = (await import('Core/ScreenManager/ScreenManager')).ScreenManager;
		this.IsNewRecord = this.StepRecordId == 0;

		if(!this.IsNewRecord){
			await LockManager.Instance.TryLock(this.StepEntityId, this.StepRecordId);
		}		

		let screen = (await screenManager.GetScreenById({ ScreenId: this._model().ScreenId, RecordId: this.StepRecordId }));
		if(screen){
			this._screen(screen as ProcessCardPage);
			this._screen().TransactionId = this._transactionId;	
		}
		BlockUI.Unblock();
	}

	Save(){
		let deferredResult = P.defer<any>();
		this._screen().Save(true, false).then((result)=>{
			this.StepRecordId = result.recordId;
			deferredResult.resolve(null);
		});

		return deferredResult.promise();
	}

	get IsActiveTabPage(): boolean {
		return this._isActive();
	}

	SetValue(value: any): void {}

	Click(): void {
		if (this._help.IsHelpButtonPressed()) {
			this._help.ShowControlHelp(this);
		} else {
			this.Trigger(EVENTS.ON_TAB_CLICK);
		}
	}

	AfterRender(el: Array<HTMLElement>) {
		super.AfterRender(el);
	}

    private GetName(): string {
        var translationList = this._model().NameTranslations;
        let currentLang = TranslationManager.Instance.GetCurrentLanguage();
        let currentLangItem = _.find(translationList, (item) => {
            return currentLang.Id === item.Language.K_Language;
        });
        if (currentLangItem) {
            this._getCurrentName = currentLangItem.Translation;
        }

        if (this._getCurrentName) {
            return this._getCurrentName;
        } else {
            return this._model().Name;
        }
    }

	get Title(): string {
		return this.GetName();
	}

	static GetDefaultName() {
		return 'New step';
	}

	AddSubControl(control: IControl) {
		this._subControls.push(control);
	}

	SelectRecord({ recordId, transactionId }: { recordId: number, transactionId: string }){
		if(this.Screen.GetEntityId() != this._screen().GetEntityId()){
			Notifier.Failed(`There is no process card page with ${this.Screen.GetEntityName()}`);
			return;
		}
		this.StepRecordId = recordId;
		this.LoadScreen(transactionId);
	}

	get EntityId(): number{
		return this._screen().GetEntityId();
	}

	get EntityName(): string{
		return this._screen().GetEntityName();
	}

	get ScreenId(): number{
		return this._model().ScreenId;
	}

	get Index(): number {
		return this._index;
	}
	set Index(index: number) {
		this._index = index;
	}

	get Process(): boolean {
		return this._process();
	}
	set Process(process: boolean) {
		this._process(process);
	}

	get Wait(): boolean {
		return this._wait();
	}
	set Wait(wait: boolean) {
		this._wait(wait);
	}

	get Finish(): boolean {
		return this._finish();
	}
	set Finish(finish: boolean) {
		this._finish(finish);
	}
}