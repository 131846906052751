import * as ko from 'knockout';
import * as _ from 'underscore';
import * as Viewer from "viewer";

import {
    GridScreenRow,
    Paginator,
    ZIndexManager,
    BlockUI,
    ResizeObserver,
    ScreenTypes,
    Event,
    Notifier,
    P,
    ConfirmationDialog,
    ConfirmationTypes,
    CONFIRMATION_DIALOG_EVENTS,
    LockManager,
    CONFIRMATIONS,
    NOTIFICATIONS,
    TranslationManager,
    CONTROL_TYPES,
    FIELD_TYPES,
    TABLE_TYPES,
    RelationshipTypes,
    EVENTS,
    GridAverageRow,
    DataCell,
    GroupColumn,
    HeaderColumn,
    GridRow,
    States,
    GridTotalRow,
    GridCellValueModel,
    GridColumnModel,
    GridDataModel,
    GridRowModel,
    QueryEntityModel,
    QueryExpressionModel,
    FieldDataModel,
    GridRowDataModel,
    TranslationModel,
    UpdateLifestatusModel,
    GridStore,
    IGetGridDataRequestModel,
    IUnlinkRecordRequestModel,
    TypeScreen,
    Util,
    FastFilterSaveModel,
    IBaseGridOptions,
    IForm,
    ILoadScreenRelationModel,
    IControl,
    SortModel,
    SortOrder,
    QueryColumnModel,
    QUERY_RESULT_GRID_EVENTS,
    PivotDetailsDropdown,
    Toolbar,
    TOOLBAR_EVENTS,
    ConfigModel,
    PROPERTIES,
    DeletedRelationModel,
    UpdateDataModel,
    extractLookupValFieldMetadata,
    ViewerJsExtention,
    PAGINATOR_EVENTS, LABELS
} from './Index';
import {IUnlinkMultipleRecordsRequestModel} from "../Stores/GridStore";

const TYPE_FIELD_NAME = 'F_TYPE';

const DEFAULT_MAX_CELL_WIDTH_PX = 600;
const DEFAULT_MIN_CELL_WIDTH_PX = 10;

const ResizeService = new ResizeObserver();

class GridSortModel {
    reservedCells: HTMLElement[];
    cells: HTMLElement[];
    row: HTMLElement
}

interface GroupedDataItem {
    Title: string;
    Value: string;
    Cell: DataCell;
}

export class BaseGrid extends Event {
    private _dataModel: GridDataModel;
    private _tableViewId: number;
    private _isAllSelected: KnockoutObservable<boolean>;
    private _headerColumns: KnockoutObservableArray<HeaderColumn>;
    private _disableEditLinkButton: KnockoutObservable<boolean> = ko.observable(false);
    private _rows: KnockoutObservableArray<GridRow>;
    private _totalRow: KnockoutObservable<GridTotalRow>;
    private _averageRow: KnockoutObservable<GridAverageRow>;
    private _totalGroupRows: Array<GridTotalRow>;
    private _enableFavoritesIfPossible: boolean;
    private _averageGroupRows: Array<GridAverageRow>;
    private _lockRows: Array<GridRow>;
    private _visible: KnockoutObservable<boolean>;
    private _isReady: KnockoutObservable<boolean>;
    private _el: HTMLElement;
    private _isEnableSelectRecord: boolean = false;
    private _isFastFilterEnabled: KnockoutObservable<boolean> = ko.observable(false);
    private _screenType: string;
    private _isNested: boolean;
    private _maxRowHeight: KnockoutObservable<string>;
    private _columnClassesCollection: KnockoutObservableArray<string>;
    private _columnClassesTotalCollection: KnockoutObservableArray<string>;
    private _hideLifeStatusToggle: KnockoutObservable<boolean>;
    private _hideUnlink: KnockoutObservable<boolean>;
    private _hideEdit: KnockoutObservable<boolean>;
    private _isEditable: KnockoutObservable<boolean>;
    private _enableBasket: KnockoutObservable<boolean>;
    private _backLinkingButtons: KnockoutObservableArray<any>;
    private _isQueryResultGrid: boolean;
    public _isPrioritiesDisabled: boolean;
    private _form: IForm;
    private _gridDataModel: IGetGridDataRequestModel;
    public existedColumnAliases: string[];
    private _queryExpression: QueryExpressionModel;
    private _isView: KnockoutObservable<boolean>;
    private _isVisibleFavorites: KnockoutObservable<boolean>;
    private _bulkEditRow: KnockoutObservable<GridRow>;
    private _uiBlocked: boolean;
    private _inlineControls: Array<IControl>;
    private _columnWidth: number[] = [];
    private _reservedColumnsWidth: number[] = [];
    private ToggleAllShownColumns;
    private _canShowColumns: boolean = false;
    private _canHideColumns: boolean = false;
    private _overflowInParentBeforeHidden: string = "";
    private _rowsReady = false;
    private _headerColumnsReady = false;
    private _nextNewRow = false;
    private _hideCont: number = 0;
    private groupColumns: object = {};
    private _sortByRelationshipOrder: KnockoutObservable<number>;
    private _sortByRelationshipOrderClassName: KnockoutComputed<string>;
    private _isEnableSortByRelationShips: KnockoutObservable<boolean>;
    private _enableSelectRowCell: boolean;
    private _toolbar: Toolbar;
    private _preventResize: boolean = false;
    private _enableLinkButton: KnockoutObservable<boolean>;
    private _enableUnlinkMultipleRecordsButton: KnockoutObservable<boolean>;
	private _enableLinkParentButton: KnockoutObservable<boolean>;
	private _hasOneParent: KnockoutObservable<boolean>;
    private _enableAddAndLinkButton: KnockoutObservable<boolean>;
    private _enableScanAndLinkButton: KnockoutObservable<boolean>;
    private _generalProperties: ConfigModel;
    private _tableScrollLeft: number = 0;
    private _unlinkedRows: Array<GridRow>;
    private _isMultiselect: boolean;
    private _waitingForGrid = null;
    private _isEditMode: KnockoutObservable<boolean>;
    private _hasSubGrid: KnockoutObservable<boolean>;
    private _prevPageNumber: number;
    private _baseTemplate: KnockoutObservable<string>;
    private _screenRows: KnockoutObservableArray<GridScreenRow>;
    public GetBaseTemplateName: KnockoutComputed<string>;
    private _isCardScreen: KnockoutObservable<boolean>
    public _deletedRows: Array<GridRow>;
    private _parentRow: GridRow;
    private _isMultipleUnlinkModeActive: KnockoutObservable<boolean> = ko.observable(false);
    private _resizeLimitInterval: number;
    private _unBlockCardScreen: KnockoutObservable<boolean>;

    ShowActionCell: KnockoutComputed<boolean>;

    constructor(options: IBaseGridOptions) {
        super();
        this._baseTemplate = ko.observable('Core/Controls/Grid/BaseGrid/Templates/DefaultTemplate');
        this._prevPageNumber = 1;
        this._isAllSelected = ko.observable(false);
        this._generalProperties = options.properties;
        this._hideLifeStatusToggle = options.hideLifeStatusToggle;
        this._hideUnlink = options.hideUnlink;
        this._hideEdit = options.hideEdit;
        this._isEditable = options.isEditable || ko.observable(null);
        this._enableBasket = options.enableBasket || ko.observable(null);
        this._enableFavoritesIfPossible = options.enabledFavorite || options.enabledFavorite === undefined;
        this._backLinkingButtons = options.backLinkingButtons || ko.observableArray([]);
        this._isQueryResultGrid = options.isQueryResultGrid || null;
        this._unBlockCardScreen = ko.observable(null);
        this._headerColumns = ko.observableArray([]);
        this._rows = ko.observableArray([]);
        this._screenRows = ko.observableArray([]);
        this._isNested = options.isNested || false;
        this._maxRowHeight = ko.observable(options.maxRowHeight || '100');
        this._isPrioritiesDisabled = options.isPrioritiesDisabled || false;
        this._form = options.form;
        this._isView = ko.observable(false);
        this._isCardScreen = ko.observable(false);
        this._isVisibleFavorites = ko.observable(false);
        this._bulkEditRow = ko.observable(null);
        this._uiBlocked = false;
        this._totalRow = ko.observable(null);
        this._averageRow = ko.observable(null);
        this._totalGroupRows = [];
        this._averageGroupRows = [];
        this._lockRows = [];
        this._enableSelectRowCell = options.enableSelectRowCell;
        this.ToggleAllShownColumns = options.ToggleAllShownColumns || function () {};
        this._isEnableSortByRelationShips = ko.observable(false);
        this._enableLinkButton = ko.observable(false);
        this._enableUnlinkMultipleRecordsButton = ko.observable(false);
		this._enableLinkParentButton = ko.observable(false);
		this._hasOneParent = ko.observable(false);
        this._enableAddAndLinkButton = ko.observable(false);
        this._enableScanAndLinkButton = ko.observable(false);
        this._isMultiselect = options.isMultiselect != null ? options.isMultiselect : true;
        this._isEditMode = ko.observable(false);

        this._visible = ko.observable(false);
        this._isReady = ko.observable(true);
        this._unlinkedRows = [];
        this._hasSubGrid = ko.observable(false);

        this._isEnableSelectRecord = options.isEnableSelectRecord || false;
        this._screenType = options.screenType;
        this._columnClassesCollection = ko.observableArray([]);
        this._columnClassesTotalCollection = ko.observableArray([]);
        this._inlineControls = [];
        this._sortByRelationshipOrder = ko.observable(SortOrder.Both);
        this._deletedRows = [];
        this._parentRow = options.ParentRow;

        this.InitEvents();
        this.GetBaseTemplateName = ko.computed(()=> this._baseTemplate() );

        this._sortByRelationshipOrderClassName = ko.computed({
            owner: this,
            read: () => {
                return this._isEnableSortByRelationShips() ? `sort-${SortOrder[this._sortByRelationshipOrder()].toLowerCase()}` : '';
            }
        });

        this.ShowActionCell = ko.computed(() => (!this._isView() && this._isEditable()
            && (!_.any(this._rows(), row => !row.ShowActionCell()))
            || this.HasInlineControls || this._hasSubGrid()));

        if (this._isNested) {
            this._toolbar = new Toolbar(
                false,
                false,
                false,
                this._enableLinkButton,
				this._enableUnlinkMultipleRecordsButton,
				this._enableLinkParentButton,
                this._hasOneParent,
                this._enableAddAndLinkButton,
                this._enableScanAndLinkButton,
                ko.observable(false),
                ko.observable(false),
                ko.observable(false),
                ko.observable(false),
                ko.observable(false),
                ko.observable(false),
                this._screenType,
                false,
                false,
                ko.observable(false),
                ko.observable(false),
                ko.observable(false),
                true,
                false,
                [],
            );

            this._toolbar.On(TOOLBAR_EVENTS.LINK_RECORD, this, () => this.Trigger(TOOLBAR_EVENTS.LINK_RECORD));
            this._toolbar.On(TOOLBAR_EVENTS.LINK_PARENT_RECORD, this, () => this.Trigger(TOOLBAR_EVENTS.LINK_PARENT_RECORD));
            this._toolbar.On(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, this, () => this.Trigger(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD));
            this._toolbar.On(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, this, () => this.Trigger(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD));

            this._toolbar.On(TOOLBAR_EVENTS.UNLINK_MULTIPLE_RECORDS, this, () => {
                this.SubGridUnlinkMultipleRecords();
                this._isMultipleUnlinkModeActive(true);
            });

            this._toolbar.On(TOOLBAR_EVENTS.CONFIRM_MULTIPLE_UNLINK, this, () => {
                this.UnlinkMultipleRecords();
                this.SubGridConfirmMultipleUnlinking();
                this._isMultipleUnlinkModeActive(false);
            });

            this._toolbar.On(TOOLBAR_EVENTS.CANCEL_MULTIPLE_UNLINK, this, () => {
                this.SubGridCancelMultipleUnlinking();
                this._isMultipleUnlinkModeActive(false);
            });

            this._toolbar.Paginator().On(PAGINATOR_EVENTS.CHANGE, this, (eventArgs: any) => {
                if (eventArgs.data.pageNumber === this._prevPageNumber) {
                    return;
                }

                if (this.GetEditRows().length > 0 || this.GetNewAndLinkRows().length > 0) {
                    const confirmationDialog = new ConfirmationDialog({
                        Text: CONFIRMATIONS.ALL_CHANGES_WILL_BE_LOST,
                        Type: ConfirmationTypes.Warning
                    });

                    confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
                        this.Trigger(EVENTS.LOAD_SUB_GRID);

                        this._prevPageNumber = eventArgs.data.pageNumber;
                    });

                    confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.DISCARD_SELECTED, this, () => {
                        this._toolbar.Paginator().PageNumber = this._prevPageNumber;
                    });

                    confirmationDialog.Show();
                } else {
                    this.Trigger(EVENTS.LOAD_SUB_GRID);

                    this._prevPageNumber = eventArgs.data.pageNumber;
                }
            });

        }
    }

    InitEvents(){
        this.AddEvent(EVENTS.SORT);
        this.AddEvent(EVENTS.OPEN_HYPERLINK);
        this.AddEvent(EVENTS.DATA_SAVED);
        this.AddEvent(EVENTS.NO_CHANGES);
        this.AddEvent(EVENTS.EDIT_LINK);
        this.AddEvent(EVENTS.CHANGE_VISIBLE_GROUP);
        this.AddEvent(EVENTS.EDIT_CLUSTERED_LINK);
        this.AddEvent(EVENTS.ADD_TO_BASKET);
        this.AddEvent(EVENTS.REMOVE_FROM_BASKET);
        this.AddEvent(EVENTS.SAVE_LIFESTATUS);
        this.AddEvent(EVENTS.REFRESH);
        this.AddEvent(EVENTS.RECORD_DELETED);
        this.AddEvent(EVENTS.RECORD_EDITED);
        this.AddEvent(EVENTS.RECORD_SAVED);
        this.AddEvent(EVENTS.UNDO_EVERY_RECORD);
        this.AddEvent(EVENTS.UPDATE_GRID);
        this.AddEvent(EVENTS.LINK_NEXT_RELATION);
        this.AddEvent(EVENTS.BACK_LINKING_POPUP_REQUESTED);
        this.AddEvent(EVENTS.SELECT_RECORD);
        this.AddEvent(EVENTS.UPDATE_SET_ENABLE_ADD_BUTTON);
        this.AddEvent(EVENTS.UNLINK_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.LINK_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.LINK_PARENT_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD);
        this.AddEvent(EVENTS.LOAD_SUB_GRID);
    }

    get IsEnabledSelectRecord(): boolean {
        return this._isEnableSelectRecord;
    }

    IsAllSelectedChanged() {
        if (this._isMultiselect) {
            this.SelectAll(this._isAllSelected());
        }
        return true;
    }

    SelectAll(value: boolean) {
        this._rows().forEach(row => row.SetIsSelected(value));
    }

    UpdateSelection() {
        const selectedRowsCount = this._rows().filter(row => row.IsSelected()).length;
        const selectAll = selectedRowsCount === this._rows().length;
        this._isAllSelected(selectAll);
    }

    ClearData() {
        this._rows([]);
    }

    get Columns() {
        return this._headerColumns();
    }

    SelectType() {
        if (this.HasFTypeColumn() || this._dataModel.QueryExpression.EntityJoins.length === 0) {
            this.NewRecord();
            return;
        }

        const objectEntityId = this._dataModel.QueryExpression.EntityJoins[0].Entity.Metadata.Id;
        const parentTypeId = objectEntityId === this._dataModel.QueryExpression.Entity.Metadata.Id ? this._form.GetScreen().GetTableTypeId() : undefined;

        const typeScreen = new TypeScreen(objectEntityId, parentTypeId, false, false);
        typeScreen.On('TYPES_NOT_FOUND', this, (eventArgs) => new Notifier().Warning(eventArgs.data.ErrorMessage));

        typeScreen.On('TYPE_SELECTED', this, (eventArgs) => {
            const typeId = eventArgs.data.TypeId;
            const kindId = eventArgs.data.KindId;

            this.NewRecord(typeId, kindId);
        });

        typeScreen.Show();
    }

    NewRecord(typeId = null, kindId: number = null): P.Promise<GridRow> {
        let deferredResult = P.defer<GridRow>();
        let subjectTable: QueryEntityModel, linkTable: QueryEntityModel, columns: QueryColumnModel[];

        if (this._dataModel.QueryExpression.EntityJoins.length > 0) {
            subjectTable = this._dataModel.QueryExpression.EntityJoins[0].Entity;
            linkTable = this._dataModel.QueryExpression.EntityJoins[0].LinkEntity;
            columns = subjectTable.Columns.concat(linkTable.Columns);
        } else if (this._dataModel.QueryExpression.SubEntityJoins.length > 0) {
            subjectTable = this._dataModel.QueryExpression.SubEntityJoins[0].Entity;
            columns = subjectTable.Columns;
        } else{
            subjectTable = this._dataModel.QueryExpression.Entity;
        }


        BlockUI.Block();
        GridStore.NewRecord({
            EntityId: subjectTable.Metadata.Id,
            LinkTableId: linkTable && linkTable.Metadata.Id,
            ParentRecordId: this._form && this._form.GetScreen().GetRecordId(),
            TypeId: typeId,
            Fields: columns
        }).always(() => {
            BlockUI.Unblock();
        }).then((result) => {
            deferredResult.resolve(this.AddNewRow(result, typeId, kindId));
            this.Trigger(EVENTS.UPDATE_SET_ENABLE_ADD_BUTTON);

        }).fail((err) => {
            new Notifier().Failed(err.message);
        });
        return deferredResult.promise();
    }

    get Rows(): Array<GridRow>{
       return this._rows();
    }

    AddRow(gridRow: GridRow) {
        this._rows.push(gridRow);
    }

    AddRowsFrom(gridDataModel: GridDataModel) {
        _.each(gridDataModel.Rows, row => {
            const newRow = row.Clone();

            _.each(this.Columns, (headerColumn, index) => {
                if (!headerColumn) {
                    const gridCellValueModel = new GridCellValueModel();

                    gridCellValueModel.EntityMetadata = headerColumn.Model.EntityMetadata;
                    gridCellValueModel.FieldMetadata = headerColumn.Model.FieldMetadata;
                    gridCellValueModel.QueryColumnGuid = headerColumn.Model.QueryColumnGuid;
                    gridCellValueModel.IsEnableEdit = headerColumn.Model.IsEnableEdit;
                    newRow.Data.splice(index, 0, gridCellValueModel);
                    gridCellValueModel.Type = gridCellValueModel.FieldMetadata.Type;
                } else {
                    newRow.Data[index].FieldMetadata = headerColumn.Model.FieldMetadata;
                    newRow.Data[index].EntityMetadata = headerColumn.Model.EntityMetadata;
                    newRow.Data[index].QueryColumnGuid = headerColumn.Model.QueryColumnGuid;
                }
            });

            this.AddNewRow(newRow);
        });
    }

    AddNewRow(rowData: GridRowModel, typeId = null, kindId: number = null): GridRow {
        this._nextNewRow = true;
        const gridRowModel = new GridRowModel();

        gridRowModel.TypeId = typeId;
        gridRowModel.KindId = kindId;

        this._dataModel.Rows.push(gridRowModel);

        let griSubjectEntity = this.GetGridSubjectEntity();
        let typeColumn = _.find(griSubjectEntity.Columns, (item) => {
            return item.Metadata.Name === TYPE_FIELD_NAME;
        });

        _.each(this._headerColumns(), column => {
            let cellData = _.find(rowData.Data, (item) => {
                return item.QueryColumnGuid === column.Model.QueryColumnGuid
            });

            const cellValueModel = new GridCellValueModel();
            cellValueModel.Value = cellData ? cellData.Value : '';
            cellValueModel.IsWrapped = false;
            cellValueModel.DisplayPriority = column.Model.DisplayPriority;
            cellValueModel.DisplayValue = cellData ? cellData.DisplayValue : '';
            cellValueModel.IsEnableEdit = typeColumn && typeColumn.Metadata.Id == column.Model.FieldMetadata.Id ? true : column.Model.IsEnableEdit;
            cellValueModel.FieldMetadata = column.Model.FieldMetadata;
            cellValueModel.EntityMetadata = column.Model.EntityMetadata;
            cellValueModel.QueryColumnGuid = column.Model.QueryColumnGuid;
            cellValueModel.Type = column.Model.FieldMetadata.Type;
            gridRowModel.Data.push(cellValueModel);

            if (column.Model.FieldMetadata.Name === TYPE_FIELD_NAME) {
                column.Model.FieldMetadata.IsReadOnly = false;
            }

        });

        const row = new GridRow(
            gridRowModel,
            this._isEditable,
            this._hideLifeStatusToggle,
            this._hideUnlink,
            this._hideEdit,
            this._enableBasket,
            this._disableEditLinkButton,
            this._backLinkingButtons,
            false,
            this._screenType,
            false,
            this._dataModel.QueryExpression,
            true,
            this._isVisibleFavorites(),
            this._maxRowHeight,
            this._form,
            this.GetInlineControls(),
            this._enableSelectRowCell,
            this._generalProperties,
            this.IsSubGrid,
            false,
            false,
            this.HasProcessCards
        );

        row.On(EVENTS.ROW_SELECTION_CHANGED, this, (eventArgs: any) => {
            if (!this._isMultiselect) {
                _.each(this._rows(), (item) => {
                    if (item != row) {
                        item.SetIsSelected(false);
                    }
                });
            }

            this.UpdateSelection();
        });
        row.On(EVENTS.EDIT_RECORD, this, (eventArgs: any) => {
            row.IsEditMode = true;
            this._isEditMode(true);
            this.Trigger(EVENTS.RECORD_EDITED);
        });

        row.On(EVENTS.CANCEL_EDIT, this, (eventArgs: any) => {
            if (eventArgs.source.State === States.New && !this._isEditMode()) {
                this._rows.splice(this._rows.indexOf(eventArgs.source), 1);
                this._dataModel.Rows.splice(this._dataModel.Rows.indexOf(row.Model), 1);
                this.Trigger(EVENTS.RECORD_DELETED);
            } else {
                eventArgs.source.IsEditMode = false;
            }
        });

        row.On(EVENTS.DELETE_RECORD, this, (eventArgs: any) => {
            this._rows.splice(this._rows.indexOf(row), 1);
            this._dataModel.Rows.splice(this._dataModel.Rows.indexOf(row.Model), 1);
            this.Trigger(EVENTS.RECORD_DELETED);
        });

        row.On(EVENTS.SAVE_RECORD, this, (eventArgs: any) => {
            row.IsEditMode = false;
            this.Trigger(EVENTS.RECORD_SAVED);
            this.ResizeTableCellsAfterRender();
        });

        row.On(EVENTS.REFRESH, this, (eventArgs) => {
            this.Trigger(EVENTS.REFRESH, {SubGrid: eventArgs.data.SubGrid, ParentRowId: eventArgs.data.ParentRowId, UpdateRow: eventArgs.data.UpdateRow});

        });
        row.On(EVENTS.ADD_TO_BASKET, this, (eventArgs: any) => this.AddToBasket(eventArgs.data.Row));
        row.On(EVENTS.REMOVE_FROM_BASKET, this, (eventArgs: any) => this.RemoveFromBasket(eventArgs.data.Row));

        row.IsEditMode = true;
        row.State = States.New;

        if (_.find(row.DataCells, (item) => item.IsEnableEdit()) != null) {
            this._rows.unshift(row);
            return row;
        } else {
            var notifier = new Notifier(this._el);
            notifier.Warning(NOTIFICATIONS.NO_EDITABLE_COLUMNS);
        }

        return row;
    }

    get NewRows(): number {
        let newRows = _.filter(this._rows(), (row)=> row.State === States.New);
        return newRows.length;
    }

    GetBulkEditRow(): GridRow {
        const gridRowModel = new GridRowModel();

        _.each(this._headerColumns(), column => {
            const cellValueModel = new GridCellValueModel();
            cellValueModel.Value = '';
            cellValueModel.IsWrapped = column.Model.IsWrapped;
            cellValueModel.DisplayPriority = column.Model.DisplayPriority;
            cellValueModel.DisplayValue = '';
            cellValueModel.IsEnableEdit = column.Model.IsEnableEdit && column.Model.FieldMetadata.Type === FIELD_TYPES.Lookup;
            cellValueModel.FieldMetadata = column.Model.FieldMetadata;
            cellValueModel.FieldMetadata.IsRequired = false;
            cellValueModel.EntityMetadata = column.Model.EntityMetadata;
            cellValueModel.QueryColumnGuid = column.Model.QueryColumnGuid;
            cellValueModel.Type = column.Model.FieldMetadata.Type;
            cellValueModel.width = column.Model.width;

            gridRowModel.Data.push(cellValueModel);

            if (column.Model.FieldMetadata.Name === TYPE_FIELD_NAME) {
                column.Model.FieldMetadata.IsReadOnly = false;
                cellValueModel.IsEnableEdit = false;
            }

        });

        const row = new GridRow(
            gridRowModel,
            this._isEditable,
            this._hideLifeStatusToggle,
            this._hideUnlink,
            this._hideEdit,
            this._enableBasket,
            this._disableEditLinkButton,
            this._backLinkingButtons,
            this._isEnableSelectRecord,
            this._screenType,
            false,
            this._dataModel.QueryExpression,
            true,
            !this._isNested,
            this._maxRowHeight,
            this._form,
            this.GetBulkEditControls(),
            this._enableSelectRowCell,
            this._generalProperties,
            this.IsSubGrid,
            false,
            false,
            this.HasProcessCards
        );

        row.IsEditMode = true;
        row.State = States.Edit;

        if (_.find(row.DataCells, (item) => item.IsEnableEdit()) != null) {
            return row;
        } else {
            var notifier = new Notifier(this._el);
            notifier.Warning(NOTIFICATIONS.NO_EDITABLE_COLUMNS);
        }
    }

    AddLinkedRows(data: GridDataModel): Array<GridRow> {
        let result: Array<GridRow> = [];
        this._disableEditLinkButton(!data.IsLinkEditorEnabled);
        this._nextNewRow = true;
        const inlineControls = this.GetInlineControls();

        _.each(data.Rows, rowModel => {
            _.each(this._headerColumns(), (headerColumn, index) => {
                const headerColumnModel = _.find(data.Columns, column => column.DisplayOrder === headerColumn.DisplayOrder);

                if (!headerColumnModel) {
                    const gridCellValueModel = new GridCellValueModel();

                    gridCellValueModel.EntityMetadata = headerColumn.Model.EntityMetadata;
                    gridCellValueModel.FieldMetadata = headerColumn.Model.FieldMetadata;
                    gridCellValueModel.QueryColumnGuid = headerColumn.Model.QueryColumnGuid;
                    gridCellValueModel.IsEnableEdit = headerColumn.Model.IsEnableEdit;
                    rowModel.Data.splice(index, 0, gridCellValueModel);
                    gridCellValueModel.Type = gridCellValueModel.FieldMetadata.Type;
                } else {
                    rowModel.Data[index].FieldMetadata = headerColumn.Model.FieldMetadata;
                    rowModel.Data[index].EntityMetadata = headerColumn.Model.EntityMetadata;
                    rowModel.Data[index].QueryColumnGuid = headerColumn.Model.QueryColumnGuid;
                }
            });

            const row = new GridRow(
                rowModel,
                this._isEditable,
                this._hideLifeStatusToggle,
                this._hideUnlink,
                this._hideEdit,
                this._enableBasket,
                this._disableEditLinkButton,
                this._backLinkingButtons,
                this._isEnableSelectRecord,
                this._screenType,
                true,
                data.QueryExpression,
                false,
                !this._isNested && this._isVisibleFavorites(),
                this._maxRowHeight,
                this._form,
                inlineControls,
                this._enableSelectRowCell,
                this._generalProperties,
                this.IsSubGrid,
                false,
                false,
                this.HasProcessCards
            );

            row.On(EVENTS.OPEN_HYPERLINK, this, (eventArgs: any) => {
                this.Trigger(EVENTS.OPEN_HYPERLINK, _.extend(eventArgs.data, {
                    CurrentRow: row,
                    RowList: data.Rows
                }));
            });

            row.On(EVENTS.UNLINK_RECORD, this, (eventArgs: any) => {
                if (this._queryExpression.IsCrossTable) {
                    this.ShowCrossTableDetail(eventArgs.source).then((selectedRow) => {
                        this.ConfirmUnlink(selectedRow);
                    });
                } else {
                    this.ConfirmUnlink(eventArgs.source);
                }
            });

            row.On(EVENTS.EDIT_RECORD, this, (eventArgs: any) => row.IsEditMode = true);
            row.On(EVENTS.CANCEL_EDIT, this, (eventArgs: any) => row.IsEditMode = false);

            row.On(EVENTS.SAVE_RECORD, this, (eventArgs: any) => {
                if (eventArgs.source.State === States.Link) {
                    row.State = States.LinkAndEdit;
                } else {
                    row.State = States.Edit;
                }

                if (this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
                    LockManager.Instance.ReleaseLock(row.EntityId, row.RecordId);
                    row.IsEditMode = false;
                }
            });

            result.push(row);
            row.State = States.Link;
            this._rows.unshift(row);
        });
        return result;
    }

    ClickOnFilterButton(headerColumn: HeaderColumn) {
        this.Trigger(EVENTS.UPDATE_GRID);
    }

    get CrossTableValueColumn(): QueryColumnModel{
        return _.find(Util.GetAllQueryColumns(this._queryExpression), col=>col.IsCrossValue);
    }

    ShowCrossTableDetail(gridRow: GridRow): P.Promise<GridRow> {
        let deferredResult = P.defer<GridRow>();
        BlockUI.Block();
        GridStore.GetCrossTableDetail({
            SubjectEntityId: this._form.GetScreen().GetEntityId(),
            SubjectRecordId: this._form.GetScreen().GetRecordId(),
            RelatedEntityId: gridRow.Model.EntityId,
            RelatedRecordId: gridRow.Model.RecordId,
            KSeqs: gridRow.Model.KSeqs,
            DisplayFields: this.CrossTableValueColumn?.DisplayFields
        }).always(() => {
            BlockUI.Unblock();
        }).then((result) => {
            let pivotDetailsDropDown = new PivotDetailsDropdown();
            pivotDetailsDropDown.On(QUERY_RESULT_GRID_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
                if (eventArgs.data.SelectedRecord.Model) {
                    let row = eventArgs.data.SelectedRecord as GridRow;
                    pivotDetailsDropDown.Close();
                    deferredResult.resolve(row);
                }
            });

            pivotDetailsDropDown.On(EVENTS.REFRESH, this, () => {
              this.Trigger(EVENTS.REFRESH);
            });

            pivotDetailsDropDown.SetData(result);

            if(pivotDetailsDropDown.Rows.length == 1){
                deferredResult.resolve(pivotDetailsDropDown.Rows[0]);
                return;
            }

            pivotDetailsDropDown.Show(gridRow.El);
        });
        return deferredResult.promise();
    }

    get FilterSaveModels(): FastFilterSaveModel[] {
        let result = [];

        this._headerColumns().forEach((item, index) => {
            if (item.FilterSaveModel) {
                result.push(item.FilterSaveModel);
            }
        });

        return result || [];
    }

    SetRecordsPerPage(recordsPerPage: number){
        if(this._isNested){
            this._toolbar.Paginator().RecordsPerPage = recordsPerPage;
        }
    }

    SetData(
        data: GridDataModel,
        restoreSortState?: boolean,
        isPrioritiesDisabled?: boolean, 
        tableViewId?: number,
        inlineControls: Array<IControl> = [],
        updateRow?: GridRow
    ){
        if(this._toolbar){
            this._toolbar.Paginator().TotalRecords = data.TotalRecords;
        }
        if(data.ScreenRows.length > 0){
            this._isCardScreen(true);
            BlockUI.Block({Target: this._el});
            this.SetScreenRowData(data)
                .then(()=> {
                    BlockUI.Unblock(this._el);
                });
        }else{
            this._isCardScreen(false);
            this.SetDefaultRowData(data, restoreSortState, isPrioritiesDisabled, tableViewId, inlineControls, updateRow);
        }
    }

    async SetScreenRowData(data: GridDataModel){
        this._baseTemplate('Core/Controls/Grid/BaseGrid/Templates/ScreenTemplate');
        this._screenRows([]);
        const screenFactory = (await import('Core/Screens/ScreenFactory')).ScreenFactory;
        let batchIndex = 0;
        let loadPromise = [];
        for (const screenModel of data.ScreenRows) { 
            let recordId = screenModel.Data.RecordId;
            screenModel.Data = null;
            let screen = screenFactory.GetScreenInstance(screenModel);
            let screenRow = new GridScreenRow(screen, this._form, recordId);

            loadPromise.push(screenRow.LoadScreenData());
            if(batchIndex >= 5){
                await Promise.all(loadPromise);
                batchIndex = 0;
                loadPromise = [];
            }

            this._screenRows.push(screenRow);
            batchIndex++;
        }
    }

    get IsEnableLink(): boolean {
        return this._generalProperties 
            && this._generalProperties.GetPropertyValue(PROPERTIES.ENABLE_SUBGRID_LINK)
            && this._screenType != ScreenTypes[ScreenTypes.Portlet]
            && this._dataModel.GridSubjectEntityType === TABLE_TYPES.Entity;
    }

    get IsEnableUnlinkMultiple(): boolean {
        return this._generalProperties
            && this._generalProperties.GetPropertyValue(PROPERTIES.ENABLE_UNLINK_MULTIPLE_RECORDS_SUBGRID)
            && this._screenType != ScreenTypes[ScreenTypes.Portlet]
            && this._dataModel.GridSubjectEntityType === TABLE_TYPES.Entity;
    }
    
    get IsEnableAddAndLink(): boolean { 
        return this._generalProperties
            && this._generalProperties.GetPropertyValue(PROPERTIES.ENABLE_SUBGID_ADD_AND_LINK)
            && this._screenType != ScreenTypes[ScreenTypes.Portlet]
            && this._dataModel.GridSubjectEntityType === TABLE_TYPES.Entity;
    }

    get IsEnabledScanAndLink(): boolean {
        return this._generalProperties
            && this._generalProperties.GetPropertyValue(PROPERTIES.ENABLE_SUBGRID_SCAN_AND_LINK)
            && this._screenType != ScreenTypes[ScreenTypes.Portlet]
            && this._dataModel.GridSubjectEntityType === TABLE_TYPES.Entity;
    }

    get IsEnableLinkParent(): boolean {
        return this._generalProperties
            && this._generalProperties.GetPropertyValue(PROPERTIES.ENABLE_SUBGRID_LINK_PARENT)
            && this._dataModel.GridSubjectEntityId === this._dataModel.GridParentEntityId
            && this._screenType === ScreenTypes[ScreenTypes.ConsultScreen]
            && this._dataModel.GridSubjectEntityType === TABLE_TYPES.Entity;
    }

    get HasProcessCards(): boolean {
        return this._generalProperties
            && this._generalProperties.GetPropertyValue(PROPERTIES.PROCESS_CARDS)
    }


    get IsEnableEdit(): boolean {
        if (this._dataModel.GridSubjectEntityMetadata.IsView) {
            if (this._screenType === ScreenTypes[ScreenTypes.ConsultScreen] || this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
                return false;
            }
        }

        if (this._screenType === ScreenTypes[ScreenTypes.Portlet]) {
            return this.HasProcessCards;
        }
        return true;
    }

    private InitLinkButtons(){
        this._enableLinkButton(this._dataModel.IsLinkingAllowed && this.IsEnableLink);
        this._enableAddAndLinkButton(this._dataModel.IsAddAndLinkAllowed && this.IsEnableAddAndLink);
        this._enableLinkButton(this._dataModel.IsLinkingAllowed && this.IsEnableLink);
        this._enableUnlinkMultipleRecordsButton(this.IsEnableUnlinkMultiple && this._dataModel.IsLinkJoin);
        this._enableAddAndLinkButton(this._dataModel.IsAddAndLinkAllowed && this.IsEnableAddAndLink);
        this._enableScanAndLinkButton(this._dataModel.IsLinkingAllowed && this._dataModel.IsScanAndLinkAllowed && this.IsEnabledScanAndLink);
		this._enableLinkParentButton(this._dataModel.IsLinkingAllowed && this.IsEnableLinkParent);
		this._hasOneParent(this._dataModel.HasOneParent);
        this._disableEditLinkButton(!this._dataModel.IsLinkEditorEnabled);
    }

    private InitHeaderColumns(tableViewId: number, restoreSortState: boolean){
        let headerColumns = [];
        _.each(this._dataModel.Columns, (columnModel, index) => {
            const queryEntity = this.GetEntityByColumnGuid(columnModel.QueryColumnGuid, this._queryExpression);

            const queryColumn = _.find(queryEntity.Columns, (column) => {
                return column.Guid === columnModel.QueryColumnGuid;
            });

            columnModel.EntityMetadata = queryEntity.Metadata;
            columnModel.FieldMetadata = queryColumn.Metadata;
            if (columnModel.IsGroup) {
                this.groupColumns[columnModel.QueryColumnGuid] = new GroupColumn(columnModel);
            } else {
                const headerColumn =
                    new HeaderColumn(columnModel, this._dataModel.SubjectRecordId, tableViewId, this._gridDataModel, this.existedColumnAliases);
               
                headerColumn.IsFastFilterEnabled = this._isFastFilterEnabled();
                const oldHeader = _.find(this._headerColumns(),
                    (item) => { return item.Model.Alias === columnModel.Alias });
              
                if (oldHeader) {
                    if (restoreSortState) {
                        headerColumn.SortOrder = oldHeader.SortOrder;
                    }
                }

                headerColumn.On(EVENTS.SORT, this, (eventArgs: any) => {
                    this._sortByRelationshipOrder(SortOrder.Both);
                    this.ResetSort(eventArgs.source);
                    var sortColumns = [];
                    _.each(this.Columns, (column) => {
                        if (column.IsEnableSort) {
                            sortColumns.push(column.GetSortModel());
                        }
                    });

                    this.Trigger(EVENTS.SORT, {SortColumns: sortColumns});
                });

                headerColumn.On(EVENTS.FAST_FILTER, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.UPDATE_GRID, {updateFromFastFilters: true});
                });

                headerColumns.push(headerColumn);
            }
        });
        return headerColumns;
    }

    SetDefaultRowData(
        data: GridDataModel,
        restoreSortState?: boolean,
        isPrioritiesDisabled?: boolean, 
        tableViewId?: number,
        inlineControls: Array<IControl> = [],
        updateRow?: GridRow       
    ) {
        this._baseTemplate('Core/Controls/Grid/BaseGrid/Templates/DefaultTemplate');

        if(this._isNested){
            this._toolbar.Paginator().TotalRecords = data.TotalRecords;
        }
        if(this._dataModel?.GridParentEntityId){
            data.GridParentEntityId = this._dataModel.GridParentEntityId;
        }

        this._tableScrollLeft = 0;
        this._isAllSelected(false);
        this._inlineControls = inlineControls;
        this._dataModel = data;
        this._tableViewId = tableViewId;
        this._isPrioritiesDisabled = isPrioritiesDisabled;
        this._queryExpression = data.QueryExpression;

        let hasAnySubgrid =  data.Rows.length > 0  ? data.Rows[0].NestedData.length > 0 : false;
        this._hasSubGrid(hasAnySubgrid);
        
        this.SetIsEnableSortByRelationships();

        this.InitLinkButtons();
        const linkedRows = _.filter(this._rows(), row => row.State === States.Link);
        const rows = ko.observableArray(linkedRows);
        
        if(!updateRow){
            this._rows([]);
        }
 
        this._isEditable(this.IsEnableEdit);

        this.existedColumnAliases = data.Columns.map((column) => column.Alias);
        const headerColumns = this.InitHeaderColumns(tableViewId, restoreSortState);

        this._isVisibleFavorites(this.IsVisibleFavorite());

        if (this._isNested) {
            this._toolbar.SetGridSubjectEntityMetadata(this.DataModel.GridSubjectEntityMetadata);
        }

        if (data.Rows.length > 0) {
            const IsGroupMode: boolean = _.some(data.Rows,(gridRow: GridRowModel) => !!gridRow.IsGroup);

            _.each(data.Rows, (rowModel) => {

                _.each(rowModel.Data, (cell) => {
                    const groupedColumn = this.groupColumns[cell.QueryColumnGuid];
                    let column;
                    if (groupedColumn) {
                        column = groupedColumn.Model;
                    } else {
                        column = data.Columns.find(i => i.QueryColumnGuid === cell.QueryColumnGuid)
                    }
                    if (column) {
                        const {FieldMetadata, EntityMetadata, SortDirection} = column;
                        cell.FieldMetadata = FieldMetadata;
                        cell.EntityMetadata = EntityMetadata;
                        cell.SortDirection = SortDirection;
                    }

                    _.each(cell.CrossWrapped, (crossWrappedCell) =>{
                        const queryEntity = this.GetEntityByColumnGuid(crossWrappedCell.QueryColumnGuid, data.QueryExpression);

                        const queryColumn = _.find(queryEntity.Columns, (column) => {
                            return column.Guid === crossWrappedCell.QueryColumnGuid;
                        });
                        crossWrappedCell.FieldMetadata = queryColumn.Metadata;
                        crossWrappedCell.EntityMetadata = queryEntity.Metadata;
                    });

                });

                let rowEntity = _.find(Util.GetAllQueryEntities(data.QueryExpression), (item) => item.Metadata.Id == rowModel.EntityId);

                let isVisibleButtons = rowEntity.Metadata.Type !== TABLE_TYPES.Sub;
                
                const row = new GridRow(
                    rowModel,
                    this._isEditable,
                    this._hideLifeStatusToggle,
                    this._hideUnlink,
                    this._hideEdit,
                    this._enableBasket,
                    this._disableEditLinkButton,
                    this._backLinkingButtons,
                    this._isEnableSelectRecord,
                    this._screenType,
                    isVisibleButtons,
                    this._dataModel.QueryExpression,
                    false,
                    this._isVisibleFavorites(),
                    this._maxRowHeight,
                    this._form,
                    this.GetInlineControls(),
                    this._enableSelectRowCell,
                    this._generalProperties,
                    this.IsSubGrid,
                    IsGroupMode,
                    data.IsLinkJoin,
                    this.HasProcessCards
                );

                if (row.HasGroupColumn) {
                    row.GroupedData = this.GetGroupedData(row.DataCells);
                }

                rows.push(row);

                row.On(EVENTS.ROW_SELECTION_CHANGED, this, (eventArgs: any) => {
                    if (!this._isMultiselect) {
                        _.each(this._rows(), (item) => {
                            if (item != row) {
                                item.SetIsSelected(false);
                            }
                        });
                    }
                    this.UpdateSelection();
                });
                row.On(EVENTS.SELECT_RECORD, this, (eventArgs: any) => this.Trigger(EVENTS.SELECT_RECORD, {Row: eventArgs.data.Row}));

                row.On(EVENTS.EDIT_RECORD, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.RECORD_EDITED);
                    if (!row.IsNewRecord && !this.IsSubEntity && this._lockRows.indexOf(row) === -1) {
                        LockManager.Instance.TryLock(row.Model.EntityId, row.Model.RecordId)
                            .then(() => {
                                row.IsEditMode = true;
                                this._lockRows.push(row);
                                this.ResizeTableCellsAfterRender();
                            })
                            .fail(() => {
                                row.IsEditMode = false;
                            });
                    } else {
                        row.IsEditMode = true;
                        BlockUI.Block({Target: this._el});
                        setTimeout(() => {
                            this.ResizeTableCellsAfterRender();
                        }, 1000);
                        BlockUI.Unblock(this._el);
                    }

                    if (this._screenType === ScreenTypes[ScreenTypes.ListScreen] || this._screenType === ScreenTypes[ScreenTypes.SpecialScreen]) {
                        rows().forEach((x) => {
                            if (x !== row) {
                                x.IsEditMode = false;
                            }
                        });
                    }
                });

                row.On(EVENTS.REFRESH, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.REFRESH, { SubGrid: eventArgs.data.SubGrid, ParentRowId: eventArgs.data.ParentRowId, UpdateRow: eventArgs.data.UpdateRow });
                });

                row.On(EVENTS.EDIT_LINK, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.EDIT_LINK, eventArgs.data);
                });

                row.On(EVENTS.EDIT_CLUSTERED_LINK, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.EDIT_CLUSTERED_LINK, { gridRow: eventArgs.data.gridRow, grid: eventArgs.data.grid });
                });

                row.On(EVENTS.LINK_NEXT_RELATION, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.LINK_NEXT_RELATION, { gridRow: eventArgs.source, field: eventArgs.data.field });
                });

                row.On(EVENTS.CANCEL_EDIT, this, (eventArgs: any) => {
                    eventArgs.source.IsEditMode = false;
                    const index = this._lockRows.indexOf(row);
                    if (index > -1) {
                        this._lockRows.splice(index, 1);
                    }
                    
                    if (rows().every(row => row.IsEditMode === false)) {
                        this.Trigger(EVENTS.UNDO_EVERY_RECORD);
                    };

                    this.Resize();
                });

                row.On(EVENTS.SAVE_RECORD, this, (eventArgs: any) => {
                    eventArgs.source.State = States.Edit;
                    if (this._screenType !== ScreenTypes[ScreenTypes.EditScreen]) {
                        this.SaveRecord(eventArgs.source).then(() => {
                            LockManager.Instance.ReleaseLock(row.EntityId, row.RecordId);
                            row.IsEditMode = false;
                        });
                    } else {
                        row.IsEditMode = false;
                        if (rows().every(row => row.IsEditMode === false)) {
                            this.Trigger(EVENTS.UNDO_EVERY_RECORD);
                        };
                    }
                });


                row.On(TOOLBAR_EVENTS.LINK_RECORD, this, (eventArgs) => {
                    this.Trigger(TOOLBAR_EVENTS.LINK_RECORD, eventArgs.data);
                });

                row.On(TOOLBAR_EVENTS.LINK_PARENT_RECORD, this, (eventArgs) => {
                    this.Trigger(TOOLBAR_EVENTS.LINK_PARENT_RECORD, eventArgs.data);
                });

                row.On(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, this, (eventArgs) => {
                    this.Trigger(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, eventArgs.data);
                });

                row.On(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, this, (eventArgs) => {
                    this.Trigger(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, eventArgs.data);
                });

                row.On(EVENTS.SAVE_LIFESTATUS, this, (eventArgs: any) => {
                    this.SaveLifestatus(eventArgs.source);
                });

                row.On(EVENTS.DELETE_RECORD, this, (eventArgs: any) => {
                    const confirmationDialog =
                        new ConfirmationDialog({
                            Text: CONFIRMATIONS.ARE_YOUR_SURE_TO_DELETE_RECORDS,
                            Type: ConfirmationTypes.Question
                        });

                    confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => this.DeleteRecord(eventArgs.source));

                    confirmationDialog.Show();
                });

                row.On(EVENTS.UNLINK_RECORD, this, (eventArgs: any) => {                    
                        if (this._queryExpression.IsCrossTable) {
                            this.ShowCrossTableDetail(eventArgs.source).then((selectedRow) => {
                                this.ConfirmUnlink(selectedRow);
                            });
                        } else {
                            this.ConfirmUnlink(eventArgs.source);
                        }
                });


                row.On(EVENTS.OPEN_HYPERLINK, this, (eventArgs: any) => {
                    this.Trigger(EVENTS.OPEN_HYPERLINK, _.extend(eventArgs.data, {
                        CurrentRow: row,
                        RowList: data.Rows
                    }));
                });

                row.On(EVENTS.ADD_TO_BASKET, this, (eventArgs: any) => this.AddToBasket(eventArgs.data.Row));
                row.On(EVENTS.REMOVE_FROM_BASKET, this, (eventArgs: any) => this.RemoveFromBasket(eventArgs.data.Row));

                row.On(EVENTS.MOVE_ROW_UP, this, (eventArgs) => {
                    this.MoveRowUp(row, eventArgs.data.SortCell);
                });

                row.On(EVENTS.MOVE_ROW_DOWN, this, (eventArgs) => {
                    this.MoveRowDown(row, eventArgs.data.SortCell);
                });

                row.On(EVENTS.HOVER_SORT, this, (eventArgs) => {
                    this.HoverSort(row, eventArgs.data.SortCell);
                });

                row.On(EVENTS.UN_HOVER_SORT, this, (eventArgs) => {
                    this.UnHoverSort(row, eventArgs.data.SortCell);
                });

                row.On(EVENTS.BACK_LINKING_POPUP_REQUESTED, this, eventArgs => {
                    this.Trigger(EVENTS.BACK_LINKING_POPUP_REQUESTED, eventArgs.data);
                });

                row.On(EVENTS.SHOW_ORIGINAL_IMAGE, this, eventArgs => {
                    this.ShowOriginalImage(eventArgs.data.dataCell);
                });

                row.On(EVENTS.RECORD_DELETED, this, () => {
                    this.Trigger(EVENTS.RECORD_DELETED);
                });

                row.On(EVENTS.LOAD_SUB_GRID, this, (eventArgs)=>{
                    this.Trigger(EVENTS.LOAD_SUB_GRID,  { SubGrid: eventArgs.data.SubGrid, ParentRowId: eventArgs.data.ParentRowId });
                });

                row.On(EVENTS.CHANGE_VISIBLE_GROUP, this, (eventArgs: any) => {
                    let currentGroupRow: GroupedDataItem = eventArgs.source._groupedData[0];
                    let isGroupActive: boolean = eventArgs.data.GroupActive;

                    this.ChangeVisibleGroup(currentGroupRow, isGroupActive);
                    this.Trigger(EVENTS.CHANGE_VISIBLE_GROUP, { gridRow: eventArgs.source });
                });

            });

            this._totalRow(
                new GridTotalRow(
                    data.TotalRow,
                    this._isVisibleFavorites(),
                    this._isEnableSelectRecord,
                    data.Columns
                )
            );
            this._averageRow(new GridAverageRow(data.AverageRow, this._isEnableSelectRecord, data.Columns));

            this._totalGroupRows = [];
            this._averageGroupRows = [];

            _.each(data.TotalGroupRows, (item) => {
                this._totalGroupRows.push(
                    new GridTotalRow(item, !this._isNested && !this._isView(), this._isEnableSelectRecord, data.Columns)
                );
            });

            _.each(data.AverageGroupRows, (item) => {
                this._averageGroupRows.push(new GridAverageRow(item, this._isEnableSelectRecord, data.Columns));
            });

            if(updateRow){
                this._rows[this._rows.indexOf(updateRow)] = rows[0];
            }else{
                this._rows(rows().map(row => {
                    row.IsEditMode = false;
                    return row;
                }));    
            }
        } else {
            this.ClearData();
        }

        this.CreateColumnWidthParameters(headerColumns);
        this.CreateColumnTotalWidthParameters(headerColumns);
        this._headerColumns(headerColumns);

        this._isReady(true);
    }

    ChangeVisibleGroup(currentGroupRow: GroupedDataItem, isGroupActive: boolean):void {
        const AllRows: GridRow[] = this._rows();
        let allGridRowGroups: GridRow[] = _.filter(AllRows,(gridRow: GridRow) => !!gridRow.GroupedData);
        let indexesOfFilteredRowGroups: number[] = _.map(allGridRowGroups, (filteredGroup: GridRow) => _.findIndex(AllRows, row => row.Guid === filteredGroup.Guid));

        let indexOfCurrentGroupRow: number = _.findIndex(AllRows,(gridRow: GridRow) => gridRow.GroupedData && gridRow.GroupedData[0].Value === currentGroupRow.Value);
        let nextGroupIndex: number = this.FindNextGreaterNumber(indexesOfFilteredRowGroups, indexOfCurrentGroupRow);
        let endIndex: number = nextGroupIndex ? nextGroupIndex : AllRows.length;

        const GroupRows: GridRow[] = AllRows.slice(indexOfCurrentGroupRow + 1, endIndex);
        _.each(GroupRows, (row: GridRow)=> {
            row.GroupVisible = isGroupActive;
            let activeTotalRow: GridTotalRow = _.find(this._totalGroupRows, (gridTotalRow: GridTotalRow)=> gridTotalRow.Guid === row.GroupGuid);
            if (activeTotalRow) {
                activeTotalRow.IsGroupActive = isGroupActive
            }

            if (row.CrossTableWrapped.length) {
                _.each(row.CrossTableWrapped, (crossRow: GridRow) => {
                    crossRow.GroupVisible = isGroupActive
                });
            }
        })

        if (isGroupActive){
            this.ResizeTableCellsAfterRender();
        }
    }

    FindNextGreaterNumber(array: number[], target: number) {
        const nextGreaterNumber = _.find(array, number => number > target);
        return nextGreaterNumber !== undefined ? nextGreaterNumber : null;
    }

    get IsSubEntity(): boolean {
        return this._dataModel.QueryExpression.SubEntityJoins.length > 0;
    }

    get IsSubGrid(){
        return !!this._parentRow;
    }

    GridUnlinkMultipleRecords() {
        _.each(this._rows(), row => {
            row.GridUnlinkMultipleRecords();
        })
    }

    GridConfirmMultipleUnlinking() {
        _.each(this._rows(), row => {
            row.GridConfirmMultipleUnlinking();
        })
    }

    GridCancelMultipleUnlinking() {
        _.each(this._rows(), row => {
            row.GridCancelMultipleUnlinking();
        })
    }

    SubGridUnlinkMultipleRecords() {
        _.each(this._rows(), row => {
            row.SubGridUnlinkMultipleRecords();
        })
    }

    SubGridConfirmMultipleUnlinking() {
        _.each(this._rows(), row => {
            row.SubGridConfirmMultipleUnlinking();
        })
    }

    SubGridCancelMultipleUnlinking() {
        _.each(this._rows(), row => {
            row.SubGridCancelMultipleUnlinking();
        })
    }

    ConfirmUnlink(row: GridRow){
        const confirmationDialog =
        new ConfirmationDialog({
            Text: CONFIRMATIONS.UNLINK_RECORD,
            Type: ConfirmationTypes.Question
        });
        
        confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
            this.UnlinkRecord(row)
        });
        confirmationDialog.Show();
    }

    SetIsEnableSortByRelationships() {
        let isEnableSortByRelationShips = this._screenType === ScreenTypes[ScreenTypes.ConsultScreen]
            && this._queryExpression.EntityJoins.length > 0
            && this._queryExpression.EntityJoins[0].Entity.Metadata.Id === this._queryExpression.Entity.Metadata.Id;
        this._isEnableSortByRelationShips(isEnableSortByRelationShips);
    }

    private GetSortRows(sortRow: GridRow, sortCell: DataCell): Array<GridRow>{
        let rows = [];
        let index = sortRow.DataCells.indexOf(sortCell);

        _.each(this._rows(),(row)=>{
            let cell = row.DataCells[index];
            let isSameRecord = true;
            _.each(cell.Model.RecordKeys,(key, index)=>{
                if(sortCell.Model.RecordKeys[index].RecordId != key.RecordId){
                    isSameRecord = false;
                }
            });  
            if(isSameRecord){
                rows.push(row);
            }
        });
        return rows;
    }

    private HoverSort(row: GridRow, sortCell: DataCell){
        let sortRows = this.GetSortRows(row, sortCell);
        if(sortRows.length > 1){
            _.each(sortRows, (row)=>{
                row.HighlightSort();
            });    
        }
    }

    private UnHoverSort(row: GridRow, sortCell: DataCell){
        let sortRows = this.GetSortRows(row, sortCell);
        _.each(sortRows, (row)=>{
            row.UnHighlightSort();
        });
    }

    private MoveRowUp(row: GridRow, upSortCell: DataCell) {
        let upSortRows = this.GetSortRows(row, upSortCell);
        let upSortCellIndex = row.DataCells.indexOf(upSortCell);
        let i = this._rows.indexOf(_.first(upSortRows));

        if (i > 0) {
            let rawRows = this._rows();
            let upRow = _.last(upSortRows);

            let downRow = rawRows[i - 1];
            let downSortCell = downRow.DataCells[upSortCellIndex];            
            let downSortRows = this.GetSortRows(downRow, downSortCell);

            if(downSortRows.length > 1){
                downRow =  _.first(downSortRows);
                downSortCell = downRow.DataCells[upSortCellIndex];      
            }

            if (this._screenType === ScreenTypes[ScreenTypes.ConsultScreen]) {
                BlockUI.Block({ Target: this._el });
                this.UpdateSort(downSortCell, upSortCell.Model.Value, upSortCell, downSortCell.Model.Value)
                    .always(()=>BlockUI.Unblock(this._el))
                    .then(() => {
                        let sortValue = upSortCell.Model.Value;
                        upSortCell.Model.Value = downSortCell.Model.Value;
                        downSortCell.Model.Value = sortValue;
                        this.Swap(upSortRows, downSortRows);
                    });
            }

            if (this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
                upRow.State = States.Edit;
                downRow.State = States.Edit;
                let sortValue = upSortCell.Model.Value;
                upSortCell.Model.Value = downSortCell.Model.Value;
                downSortCell.Model.Value = sortValue;
                this.Swap(upSortRows, downSortRows);
            }
        }
    }

    private MoveRowDown(row: GridRow, downSortCell: DataCell) {
        let downSortRows = this.GetSortRows(row, downSortCell);
        let downSortCellIndex = row.DataCells.indexOf(downSortCell);
        let i = this._rows.indexOf(_.last(downSortRows));

        if (i < this._rows().length - 1) {
            let rawRows = this._rows();
            let downRow = _.first(downSortRows);
            let upRow = rawRows[i + 1];           
            let upSortCell = upRow.DataCells[downSortCellIndex];

            let upSortRows = this.GetSortRows(upRow, upSortCell);

            if(upSortRows.length > 1){
                upRow =  _.last(upSortRows);
                upSortCell = upRow.DataCells[downSortCellIndex];      
            }

            if (this._screenType === ScreenTypes[ScreenTypes.ConsultScreen]) {
                BlockUI.Block({ Target: this._el });
                this.UpdateSort(downSortCell, upSortCell.Model.Value, upSortCell, downSortCell.Model.Value)
                    .always(()=>BlockUI.Unblock(this._el))
                    .then(() => {
                        let sortValue = downSortCell.Model.Value;
                        downSortCell.Model.Value = upSortCell.Model.Value;
                        upSortCell.Model.Value = sortValue;
                        this.Swap(upSortRows, downSortRows);
                    });
            }

            if (this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
                upRow.State = States.Edit;
                downRow.State = States.Edit;
                let sortValue = downSortCell.Model.Value;
                downSortCell.Model.Value = upSortCell.Model.Value;
                upSortCell.Model.Value = sortValue;
                this.Swap(upSortRows, downSortRows);
            }
        }
    }

    Swap(upRows: Array<GridRow>, downRows: Array<GridRow>) {
        let upIndex = this._rows.indexOf(downRows[0]);
        
        _.each(upRows, (upRow)=>{
            this._rows.splice(this._rows.indexOf(upRow), 1);
            this._rows.splice(upIndex, 0, upRow);
            upIndex++;
        });

        this._rows.valueHasMutated();
    }

    private UpdateSort(downSortCell: DataCell, downSortValue: string, upSortCell: DataCell, upSortValue: string): P.Promise<null> {
        const deferred = P.defer<null>();
        let upFieldData = new FieldDataModel();
        upFieldData.EntityId = upSortCell.EntityId;
        upFieldData.FieldId = upSortCell.FieldId;
        upFieldData.RecordKeys = upSortCell.Model.RecordKeys;
        upFieldData.Value = upSortValue;

        let downFieldData = new FieldDataModel();
        downFieldData.EntityId = downSortCell.EntityId;
        downFieldData.FieldId = downSortCell.FieldId;
        downFieldData.RecordKeys = downSortCell.Model.RecordKeys;
        downFieldData.Value = downSortValue;
        BlockUI.Block({Target: this._el});
        GridStore.UpdateSort({UpCell: upFieldData, DownCell: downFieldData})
            .then(result => {
                const warning = result.Warnings ? _.first(result.Warnings) : null;
                if(warning) {
                    new Notifier().Warning(warning);
                }
                deferred.resolve(null);
            })
            .fail((err) => {
                new Notifier().Failed(err.message);
            })
            .always(() => {
                BlockUI.Unblock(this._el);
            });

        return deferred.promise();
    }

    GetTotalGroup(guid: string) {
        return _.find(this._totalGroupRows, (item) => {
            return item.Guid === guid
        });
    }

    GetAverageGroup(guid: string) {
        return _.find(this._averageGroupRows, (item) => {
            return item.Guid === guid
        });
    }

    IsVisibleFavorite() {
        if (this._enableFavoritesIfPossible) {
            if (this._isNested) {
                return false;
            }

            if (this._queryExpression.EntityJoins.length == 0 && this._queryExpression.SubEntityJoins.length > 0) {
                return false;
            }

            if(this._isView()){
                return false;
            }

            return true;
        }

        return false;
    }

    isFastFilterExist(type: string): boolean {
        if (!this.IsFastFilterEnabled) {
            return false;
        }
        switch (type) {
            case FIELD_TYPES.Decimal:
            case FIELD_TYPES.Integer:
            case FIELD_TYPES.Date:
            case FIELD_TYPES.DateTime:
            case FIELD_TYPES.TimeSpan:
            case FIELD_TYPES.Time:
            case FIELD_TYPES.Text:
            case FIELD_TYPES.YesNo:
            case FIELD_TYPES.Lookup:
                return true;
            default:
                return false;
        }
    }

    HideGrid() {
        this._hideCont++;
        if (!this._uiBlocked && this._el) {
            this._tableScrollLeft = this._el.parentElement.scrollLeft;
            this._el.classList.add("hidden-table");
            this._el.parentElement.scrollLeft = 0; // reset scroll of table
            this._overflowInParentBeforeHidden = this._el.parentElement.style.overflow; // save overflow valuer before change it
            this._el.parentElement.style.overflow = "hidden"; // reset scroll of table
            this._uiBlocked = true;
        }
    }

    ShowGrid(removeScroll?: boolean) {
        this._hideCont--;
        if (this._hideCont <= 0 && this._uiBlocked && this._el) {
            this._hideCont = 0;
            this._el.classList.remove("hidden-table");
            this._el.parentElement.style.overflow = this._overflowInParentBeforeHidden; // return back overflow that was before hiddening
            this._uiBlocked = false;
            this._el.parentElement.scrollLeft = this._tableScrollLeft;
        }
    }

    AfterRowRender() {
        this.ShowGrid();

        if (this._nextNewRow) {
            this._nextNewRow = false;
            this.ResizeTableCellsAfterRender();
            return;
        } else {
            if (this._headerColumnsReady) {
                this.ResizeTableCellsAfterRender();
                setTimeout(() => {
                    this.ResizeTableCellsAfterRender(); // subgrid header recalculation after saving (not always reproducible)
                }, 0);
                this._headerColumnsReady = false;
                this._rowsReady = false;
            }
        }
    }


    AfterHeaderColumnsReady(el, column) {
        if (this._el && this._headerColumns().indexOf(column) + 1 === this._headerColumns().length) {
            this._headerColumnsReady = true;

            if (this._rowsReady || !this._rows().length) {
                this.ResizeTableCellsAfterRender();
                this._headerColumnsReady = false;
                this._rowsReady = false;
            }
        }
    }

    AfterBulkEditRowReady() {
        this.ResizeTableCellsAfterRender();
    }


    private WaitTillGridShown(): P.Promise<any> {
        let deferredResult = P.defer<any>();

        if (this._el.offsetWidth !== 0) {
            deferredResult.resolve(null);
        } else {
            clearInterval(this._waitingForGrid);
            const startTime = new Date().getTime();
            this._waitingForGrid = setInterval(() => {
                if (this._el.offsetWidth !== 0) {
                    clearInterval(this._waitingForGrid);
                    deferredResult.resolve(null);
                } else if (startTime + 30000 < new Date().getTime()) { // if table wouldnt shown after half minute stop waiting
                    clearInterval(this._waitingForGrid);
                    deferredResult.reject({message: 'error'});
                }
            }, 100);
        }

        return deferredResult.promise();
    }

    AlignCells(): GridSortModel[] {
        let table = $(this._el).find('> div > .flex-grid-row:not(.subTableContainer)');
        const rows: HTMLElement[] = Array.from(table.length === 0 ? this._el.querySelectorAll(".flex-grid-row") : table);
        const rowCells: GridSortModel[] = [];
        this._reservedColumnsWidth = [];
        this._columnWidth = [];

        rows.forEach((row, rowIndex) => {
            const allCells: HTMLElement[] = Array.from(row.querySelectorAll(".flex-grid-hdata, .flex-grid-tdata:not(.flex-grid-nested-table)"));
            const reservedCells: HTMLElement[] = [];
            const cells: HTMLElement[] = [];
            let i = 0;
            let columnIndex = 0;
            let reservedIndex = 0;

            if (!row.classList.contains("sortDescriptionColumn")) {
                while (i < allCells.length) {
                    const cell = allCells[i];
                    let iterationTarget;
                    let iterationIndex: number;
                    if (cell.classList.contains("js-reserved")) {
                        iterationTarget = this._reservedColumnsWidth;
                        iterationIndex = reservedIndex++;
                        reservedCells.push(cell);
                    } else {
                        iterationTarget = this._columnWidth;
                        iterationIndex = columnIndex++;
                        cells.push(cell);
                    }
                    const columnWidth = iterationTarget[iterationIndex] || 0;
                    cell.style.width = "";
                    cell.style.display = "";
                    iterationTarget[iterationIndex] = Math.max(cell.offsetWidth, columnWidth);
                    i++;
                }
            }

            rowCells[rowIndex] = {
                cells, reservedCells, row
            };
        });

        this._el.querySelector<HTMLDivElement>(".flex-grid-head").style.removeProperty("width");
        this._el.querySelector<HTMLDivElement>(".flex-grid-body").style.removeProperty("width");

        rowCells.forEach((item) => {
            item.reservedCells.forEach((cell, cellIndex) => {
                cell.style.width = `${Math.min(DEFAULT_MAX_CELL_WIDTH_PX, this._reservedColumnsWidth[cellIndex] + 1)}px`;
            });

            item.cells.forEach((cell, cellIndex) => {
                const minWidth = Math.max(DEFAULT_MIN_CELL_WIDTH_PX, this._columnWidth[cellIndex] + 1);
                let context = ko.dataFor(cell);

                if ((context instanceof DataCell || context instanceof HeaderColumn) && context.Model.FieldMetadata && context.Model.FieldMetadata.Type === FIELD_TYPES.MultiSelect) {
                    cell.style.width = `${minWidth}px`;
                } else {
                    cell.style.width = `${Math.min(minWidth, DEFAULT_MAX_CELL_WIDTH_PX)}px`;
                }
            });
        });

        return rowCells;
    }

    ResizeTableCellsAfterRender(afterPriority?: GridSortModel[]) {
        let superHeaderRows = $(this._el).find(".super-flex-grid-row");
        if(superHeaderRows.length == 0) {
            return;
        }

        superHeaderRows.css('display', 'none');

        this.WaitTillGridShown()
            .then(() => {
                const isCrossTable = (this._queryExpression && this._queryExpression.IsCrossTable);
                let rowsData: GridSortModel[] = afterPriority;
                let maxRowWidth = 0;
                const isSubTable = this._visible();
                this.HideGrid();

                if (!afterPriority) {
                    rowsData = this.AlignCells();
                    this._canShowColumns = false;
                }

                //fix if grid has wrapped  columns
                const [first, second, third] = rowsData;
                let recalculateGrowColumnsToFullWidth = false;
                if (third != null && first != null) {
                    if (first.row.offsetWidth <= third.row.offsetWidth && $(third.row).hasClass('related')) {
                        recalculateGrowColumnsToFullWidth = true;
                    }
                }

                const containerWidth = this._el.offsetWidth;
                let containerScrollWidth = this._el.scrollWidth;
                let isQueryResult = $(this._el).hasClass('query-result-grid');

                let boxMoreEqualContent = containerScrollWidth <= containerWidth;
                let contentMoreEqualBox = containerScrollWidth >= containerWidth;

                if (isSubTable) {
                    if (boxMoreEqualContent || recalculateGrowColumnsToFullWidth) {
                        maxRowWidth = this.GrowColumnsToFullWidth({rowsData, containerWidth});

                    } else if ((contentMoreEqualBox) || (contentMoreEqualBox && isQueryResult)) {
                        this.HideColumns(maxRowWidth, rowsData);
                    } else if (!afterPriority && !this._isPrioritiesDisabled) {
                        this.HideColumnsByPriority(containerScrollWidth - containerWidth, rowsData);
                        this.ShowGrid();
                        return;
                    } else {
                        this.HideColumns(maxRowWidth, rowsData);
                    }

                } else if (isQueryResult) { // QueryResult page
                    if (boxMoreEqualContent || recalculateGrowColumnsToFullWidth) {
                        maxRowWidth = this.GrowColumnsToFullWidth({rowsData, containerWidth});
                    } else if (!afterPriority && !this._isPrioritiesDisabled) {
                        this.HideColumnsByPriority(containerScrollWidth - containerWidth, rowsData);
                        this.ShowGrid();
                        return;
                    } else {
                        this.HideColumns(maxRowWidth, rowsData);
                    }
                } else {
                    if (boxMoreEqualContent || recalculateGrowColumnsToFullWidth) {
                        maxRowWidth = this.GrowColumnsToFullWidth({rowsData, containerWidth});

                    } else if ((contentMoreEqualBox && isQueryResult)) {
                        this.HideColumns(maxRowWidth, rowsData);

                    } else if (!afterPriority && !this._isPrioritiesDisabled) {
                        if (!isCrossTable){
                            this.HideColumnsByPriority(containerScrollWidth - containerWidth, rowsData);
                        }
                        if(isCrossTable){
                            this.SetSuperHeader();
                        }
                        this.ShowGrid();
                        return;
                    } else {
                        this.HideColumns(maxRowWidth, rowsData);
                    }
                }
                this.ToggleAllShownColumns(this._canHideColumns, this._canShowColumns);
                this.SetSuperHeader();
                this.ShowGrid();
            });

    }

    GrowColumnsToFullWidth({rowsData, containerWidth}) {
        const [firstRow, ...restRows] = rowsData;
        const reservedFieldsWidth = firstRow.reservedCells.reduce((prev, next) => prev + next.offsetWidth, 0);

        const restWidth = containerWidth - reservedFieldsWidth;
        const restColumnsWidth = firstRow.cells.reduce((prev, next) => prev + next.offsetWidth, 0);

        firstRow.row.style.width = "";
        firstRow.cells.forEach((element, index) => {// calculate columns width on header columns
            const percentWidth = element.offsetWidth / restColumnsWidth;
            const newWidth = percentWidth * restWidth;
            element.style.width = newWidth + "px";
            this._columnWidth[index] = newWidth;
        });

        const maxRowWidth = firstRow.row.offsetWidth;
        if (restRows && restRows.length) {
            restRows.forEach(item => {// set colums width to each cell on each row
                item.row.style.width = "";
                item.cells.forEach((cell, index) => {
                    cell.style.width = this._columnWidth[index] + "px";
                });
            });
        }

        this.ApplyMaxRowWidth(maxRowWidth);

        this._canHideColumns = false;

        return maxRowWidth;
    }

    private ApplyMaxRowWidth(maxRowWidth: number) {
        this._el.querySelector<HTMLDivElement>(".flex-grid-head").style.width = `${maxRowWidth}px`;
        this._el.querySelector<HTMLDivElement>(".flex-grid-body").style.width = `${maxRowWidth}px`;
    }

    HideColumns(maxRowWidth: number, rowsData: GridSortModel[]) {
        this._canHideColumns = true;
        maxRowWidth = 0;
        // grow all rows to 100% of table scroll width
        rowsData.forEach(item => {
            maxRowWidth = Math.max(maxRowWidth, item.row.offsetWidth);
        });

        this.ApplyMaxRowWidth(maxRowWidth);
    }

    HideColumnsByPriority(difference: number, rowsData: GridSortModel[]) {
        const [headersArray, ...rowsArray] = rowsData;
        let hiddenColumns = 0;
        let hiddenElementsWidth = 0;
        let haveAlwaysVisibleCells = false;
        let maxCellsLength = headersArray.cells.length;
        let maxColumsToHide = headersArray.cells.reduce((prevItem, nextItem) => {
            if (nextItem.dataset.priority !== "-1") {
                prevItem++;
            } else {
                haveAlwaysVisibleCells = true;
            }
            return prevItem;
        }, 0);

        maxColumsToHide = haveAlwaysVisibleCells ? maxColumsToHide : Math.max(maxColumsToHide - 1, 0);

        headersArray.cells.sort(this.SortColumnsByPriority);

        if (maxColumsToHide === 0) {
            this._canShowColumns = false;
            this._canHideColumns = false;
            this.ResizeTableCellsAfterRender(rowsData);
            return;
        }

        for (let elem of headersArray.cells) {
            if (hiddenElementsWidth >= difference || hiddenColumns === maxColumsToHide) {
                break;
            }
            hiddenElementsWidth += elem.offsetWidth;
            elem.style.display = "none";
            hiddenColumns++;
        }
        if (rowsArray) {
            for (let row of rowsArray) {
                if (!row.cells.length) continue;

                //fix if grid has wrapped  columns
                row.cells = _.filter(row.cells, (cell) => {
                    let dataFor = ko.dataFor(cell);
                    if (dataFor instanceof DataCell) {
                        if (!dataFor.IsOrdinary) {
                            return false;
                        }
                    }

                    return true;
                });

                row.cells.sort(this.SortColumnsByPriority);
                let localHaveAlwaysVisibleCells = haveAlwaysVisibleCells;
                let localMaxColumsToHide = maxColumsToHide;

                if (maxCellsLength !== row.cells.length) {
                    localMaxColumsToHide = row.cells.reduce((prevItem, nextItem) => {
                        if (nextItem.dataset.priority !== "-1") {
                            prevItem++;
                        } else {
                            localHaveAlwaysVisibleCells = true;
                        }
                        return prevItem;
                    }, 0);

                    localMaxColumsToHide = localHaveAlwaysVisibleCells ? localMaxColumsToHide : Math.max(localMaxColumsToHide - 1, 0);
                }
                const localHiddenColumns = Math.min(hiddenColumns, localMaxColumsToHide);
                for (let i = 0; i < localHiddenColumns; i++) {
                    row.cells[i].style.display = "none";
                }
            }
        }
        this._canShowColumns = hiddenColumns > 0;
        this.ResizeTableCellsAfterRender(rowsData);
    }

    SetSuperHeader() {
        const fragment = document.createDocumentFragment();
        const superHeaderRow: HTMLDivElement = this._el.querySelector(".super-flex-grid-row");
        const cellsEls: HTMLDivElement[] = Array.from(this._el.querySelectorAll(".flex-grid-hdata"));
        const headerCells = cellsEls.reduce((prev, now) => {
            if (now.style.display === "none") {
                return prev;
            }
            if (now.classList.contains("js-reserved")) {
                prev.reservedWidth += now.offsetWidth;
            } else {
                prev.regular.push(now);
            }
            return prev;
        }, {reservedWidth: 0, regular: []});
        if (!headerCells.regular.length) {
            this._el.classList.remove("has-super-header");
            superHeaderRow.style.display = "none";
            return;
        }
        superHeaderRow.querySelectorAll(".super-header-cell").forEach(el => el.remove());

        const groupEl = document.createElement("div");
        groupEl.className = "super-header-cell";

        const overReservedEl = groupEl.cloneNode(false);
        (overReservedEl as HTMLDivElement).style.width = headerCells.reservedWidth + "px";
        fragment.appendChild(overReservedEl);

        let currentGroup;
        let currentGroupEl = null;
        const groupsWidth = [];
        let groupIndex = -1;
        let hasGroups = false;

        headerCells.regular.forEach(el => {
            if (el.dataset.group === currentGroup) {
                groupsWidth[groupIndex] += el.offsetWidth;
                currentGroupEl.style.width = groupsWidth[groupIndex] + "px";
            } else {
                groupIndex++;
                currentGroup = el.dataset.group;
                currentGroupEl = groupEl.cloneNode(false);
                currentGroupEl.innerText = currentGroup;
                groupsWidth[groupIndex] = el.offsetWidth;
                currentGroupEl.style.width = groupsWidth[groupIndex] + "px";
                fragment.appendChild(currentGroupEl);

                if (currentGroup) {
                    hasGroups = true;
                }
            }
        });

        if (hasGroups) {
            this._el.classList.add("has-super-header");
            superHeaderRow.appendChild(fragment);
            superHeaderRow.style.display = "flex";
        } else {
            this._el.classList.remove("has-super-header");
            superHeaderRow.style.display = "none";
        }
    }

    SortColumnsByPriority(a: HTMLDivElement, b: HTMLDivElement): number {
        const datasetA = a.dataset;
        const datasetB = b.dataset;

        const priorityA = +datasetA.priority || 4; // if no priority we set 4 (the lowest priority)
        const priorityB = +datasetB.priority || 4; // if no priority we set 4 (the lowest priority)

        // compare priority
        if (priorityA < priorityB) {
            return 1;
        } else if (priorityA > priorityB) {
            return -1;
        }

        // if priorities equals compare cell index
        if (+datasetA.index < +datasetB.index) {
            return 1;
        } else if (+datasetA.index > +datasetB.index) {
            return -1;
        }

        return 0;
    }

    getColumnWidth = (data) => {
        const column = this.Model.Columns[data];
        if (column) {
            return column.styleWidth
        }
        return "";
    };

    GetColumnPriority(index) {
        return this.Model.Columns[index].DisplayPriority;
    }

    GetClassNames(model: any, classNames?: string[]): string {
        let result: string;
        let additionalClassNames: string;

        if (model.FieldMetadata) {
            result =
                model.FieldMetadata.Type.toString() + ' ' + model.FieldMetadata.FormatName.toString().replace(' ', '');

            if (model.FieldMetadata.Type === FIELD_TYPES.Lookup) {
                const validationFieldMetadata = extractLookupValFieldMetadata(model.FieldMetadata);

                if (validationFieldMetadata) {
                    result += ` ${validationFieldMetadata.Type} ${validationFieldMetadata.FormatName.toString().replace(
                        ' ',
                        ''
                    )}`;
                }
            }
        } else if (model.Type) {
            result = model.Type;
        } else {
            result = '';
        }

        if (classNames) {
            additionalClassNames = classNames.join(' ');

            return result + ' ' + additionalClassNames;
        }

        return result;
    }

    CreateColumnWidthParameters(headers: HeaderColumn[]): void {
        const classes = headers.map((header) => this.GetClassNames(header.Model));

        this._columnClassesCollection(classes);
    }

    CreateColumnTotalWidthParameters(headers: HeaderColumn[]): void {
        const filterHeader = _.filter(headers, header=> !header.Model.IsSortDescription);
        const classes = filterHeader.map(header => this.GetClassNames(header.Model));
        this._columnClassesTotalCollection(classes);
    }

    AddColumns(columnModels: Array<GridColumnModel>) {
        const columns = [];

        _.each(columnModels, columnModel => {
            const column = new HeaderColumn(columnModel, null, null, null, []);
            columns.push(column);
        });

        this._headerColumns(columns);
    }

    ResetSort(excludeColumn: HeaderColumn) {
        _.each(this._headerColumns(), item => {
            if (item !== excludeColumn) {
                item.ResetSort();
            }
        });
    }

    SaveRecord(row: GridRow): P.Promise<any> {
        const deferredResult = P.defer<GridDataModel>();
        const rowDataModel = this.SerializeRowData(row);

        BlockUI.Block();
        GridStore.UpdateRow(rowDataModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(data => {
                deferredResult.resolve(null);
                this.Trigger(EVENTS.DATA_SAVED, { UpdateResult: data, UpdateRow: row });
                this.ResizeTableCellsAfterRender();
                this.Trigger(EVENTS.LOAD_SUB_GRID);
            });
        return deferredResult.promise();
    }

    SaveLifestatus(row: GridRow): P.Promise<any> {
        const dataModel = new UpdateLifestatusModel({
            EntityId: row.EntityId,
            RecordId: row.RecordId,
            RowLifestatus: row.LifestatusId
        });

        BlockUI.Block();
        let deferredResult = P.defer<GridDataModel>();
        GridStore.UpdateRowLifestatus(dataModel)
            .always(() => {
                BlockUI.Unblock();
            })
            .then(data => {
                deferredResult.resolve(null);
                this.Trigger(EVENTS.REFRESH);
            });

        return deferredResult.promise();
    }

    DeleteRecord(row: GridRow) {
        if(this._screenType === ScreenTypes[ScreenTypes.EditScreen]){
            row.State = States.Delete;
            this._rows.splice(this._rows.indexOf(row), 1);
            this._deletedRows.push(row);
        }else{
            const rowDataModel = this.SerializeRowData(row);
            GridStore.DeleteRow(rowDataModel)
                .then(result => {
                    var notifier = new Notifier(this._el);
                    if (result.IsSuccessfull) {
                        notifier.Success(NOTIFICATIONS.RECORD_REMOVED);
                        this._rows.splice(this._rows.indexOf(row), 1);
                        this._dataModel.Rows.splice(this._dataModel.Rows.indexOf(row.Model), 1);
                        this.Trigger(EVENTS.RECORD_DELETED);
                    } else {
                        notifier.Failed(NOTIFICATIONS.ERROR_DELETE_GRID_DATA.replace('{ErrorMessage}', result.ErrorMessage));
                    }
                });    
        }
    }

    get SubjectEntityId() {
        return this._parentRow ? this._parentRow.EntityId : this._dataModel.SubjectEntityId;
    }

    get SubjectRecordId(){
        return this._parentRow ? this._parentRow.RecordId : this._dataModel.SubjectRecordId;
    }

    UnlinkMultipleRecords() {
        let selectedRows: Array<IUnlinkRecordRequestModel> = [];
        let selectedRowsRequestModel: IUnlinkMultipleRecordsRequestModel = null;

        _.each(this._dataModel.Rows, row => {
            if (row.IsUnlinkCheckboxChecked) {
                let requestRowModel: IUnlinkRecordRequestModel = {
                    MainEntityId: this.SubjectEntityId,
                    MainRecordId: this.SubjectRecordId,
                    RelatedEntityId: row.EntityId,
                    RelatedRecordId: row.RecordId,
                    KSeq: row.KSeq,
                    RelationshipType: row.RelationshipType
                };

                if (row.RelationshipType == RelationshipTypes.Parent) {
                    requestRowModel.MainEntityId = row.EntityId;
                    requestRowModel.MainRecordId = row.RecordId;
                    requestRowModel.RelatedEntityId = this.SubjectEntityId;
                    requestRowModel.RelatedRecordId = this.SubjectRecordId;
                    requestRowModel.RelationshipType = row.RelationshipType;
                }

                selectedRows.push(requestRowModel);

                selectedRowsRequestModel = {
                    Rows: selectedRows
                }
            }
        });

        const notifier = new Notifier(null);

        if (selectedRowsRequestModel) {
            BlockUI.Block();

            GridStore.UnlinkMultipleRecords(selectedRowsRequestModel)
                .always(() => {
                    BlockUI.Unblock();
                })
                .then(result => {

                    if (result.IsSuccessfull) {

                        let elementsToDelete = [], dataModelRowsToDelete = [];

                        _.each(this._dataModel.Rows, row => {
                            if (row.IsUnlinkCheckboxChecked) {
                                let deletionIndex = this._dataModel.Rows.indexOf(row);
                                elementsToDelete.push(this._rows()[deletionIndex]);
                                dataModelRowsToDelete.push(row);
                            }
                        });

                        this._rows(this._rows().filter(row => !elementsToDelete.includes(row)));
                        this._dataModel.Rows = this._dataModel.Rows.filter(row => !dataModelRowsToDelete.includes(row));

                        if (!this._parentRow) {
                            this.Trigger(EVENTS.REFRESH);
                        }
                    } else if (result.Warnings && result.Warnings.length > 0) {
                        _.each(result.Warnings, (warning) => notifier.Warning(warning));
                    } else {
                        notifier.Failed(result.ErrorMessage);
                    }
                });

            selectedRowsRequestModel = null;
        } else {
            notifier.Warning(LABELS.NO_RECORD_SELECTED);
        }
    }

    UnlinkRecord(row: GridRow): any {
        if (row.State != States.Link) {
            let requestModel: IUnlinkRecordRequestModel = {
                MainEntityId: this.SubjectEntityId,
                MainRecordId: this.SubjectRecordId,
                RelatedEntityId: row.EntityId,
                RelatedRecordId: row.RecordId,
                KSeq: row.KSeq
            }

            if (row.RelationshipType == RelationshipTypes.Parent) {
                requestModel.MainEntityId = row.EntityId;
                requestModel.MainRecordId = row.RecordId;
                requestModel.RelatedEntityId = this.SubjectEntityId;
                requestModel.RelatedRecordId = this.SubjectRecordId;
            }

            if (this._screenType === ScreenTypes[ScreenTypes.EditScreen] && !this._parentRow) {
                if (!row.LinkEditorData) {
                    row.LinkEditorData = new UpdateDataModel();
                }
                let deletedRelation = new DeletedRelationModel(row.EntityId, row.RecordId, row.KSeq, row.RelationshipType);
                row.LinkEditorData.DeletedRelations.push(deletedRelation);
                this._rows.splice(this._rows.indexOf(row), 1);
                this._unlinkedRows.push(row);
                this.Trigger(EVENTS.UNLINK_RECORD);
            } else {
                BlockUI.Block();
                GridStore.UnlinkRecord(requestModel)
                    .always(() => {
                        BlockUI.Unblock();
                    })
                    .then(result => {
                        const notifier = new Notifier(null);

                        if (result.IsSuccessfull) {
                            this._rows.splice(this._rows.indexOf(row), 1);
                            if(!this._parentRow){
                                this.Trigger(EVENTS.REFRESH);
                            }
                        } else if (result.Warnings && result.Warnings.length > 0) {
                            _.each(result.Warnings, (warning) => notifier.Warning(warning));
                        } else {
                            notifier.Failed(result.ErrorMessage);
                        }
                    });
            }
        } else {
            this._rows.splice(this._rows.indexOf(row), 1);
        }
    }

	UnlinkRecordByRelation(relation: ILoadScreenRelationModel, isNewParent: boolean): any {
		let requestModel: IUnlinkRecordRequestModel = {
			MainEntityId: relation.MainEntityId,
			MainRecordId: relation.MainRecordId,
			RelatedEntityId: relation.RelatedEntityId,
			RelatedRecordId: relation.RelatedRecordId,
			KSeq: relation.KSeq
		}

		BlockUI.Block();
		GridStore.UnlinkRecord(requestModel)
			.always(() => {
				BlockUI.Unblock();
			})
			.then(result => {
                const notifier = new Notifier(null);

				if (result.IsSuccessfull) {
					const row = this.GetRowByRelation(relation, isNewParent);
					if (row) {
						this._rows.splice(this._rows.indexOf(row), 1);
					}
				} else if (result.Warnings && result.Warnings.length > 0) {
                    _.each(result.Warnings, (warning) => notifier.Warning(warning));
                } else {
					notifier.Failed(result.ErrorMessage);
				}
			});
	}

    get RowsCount(): number{
        let rows = _.filter(this._rows(), (item)=> !item.Model.IsGroup);
        return rows.length;
    }

    get TotalRows(){        
        return this._dataModel.TotalRecords + this.NewRows
    }

    SerializeRowData(row: GridRow): GridRowDataModel {
        const rowDataModel = new GridRowDataModel();
        if (row.RelationshipType == RelationshipTypes.Parent) {
            rowDataModel.SubjectEntityId = row.EntityId;
            rowDataModel.SubjectRecordId = row.RecordId;
            rowDataModel.RecordId = this._dataModel.SubjectRecordId;
            rowDataModel.EntityId = this._dataModel.SubjectEntityId;

        } else {
            rowDataModel.SubjectEntityId = this._dataModel.SubjectEntityId;
            rowDataModel.SubjectRecordId = this._dataModel.SubjectRecordId;
            rowDataModel.RecordId = row.RecordId;
            rowDataModel.EntityId = row.EntityId;
        }

        rowDataModel.IsLinkParent = row.IsLinkParent;

        rowDataModel.State = row.State;
        _.each(row.DataCells, item => {
            const aliasToBeAdded = item.EntityId == rowDataModel.EntityId && item.Model.FieldMetadata.Type === FIELD_TYPES.Alias;
            if (!item.IsReadOnly() || aliasToBeAdded) {
                const fieldData = new FieldDataModel();
                fieldData.Value = item.Value;
                fieldData.CustomValue = item.Model.FieldMetadata.Type == FIELD_TYPES.Lookup && item.DisplayValue;
                fieldData.FieldId = item.FieldId;
                fieldData.EntityId = item.EntityId;
                fieldData.RecordKeys = item.Model.RecordKeys || [];
                fieldData.Translations = this.GetTranslations(item);

                rowDataModel.Data.push(fieldData);
            }
        });
        if (this.HasFTypeField() && !this.HasFTypeColumn() && row.Model.TypeId) {
            rowDataModel.Data.push(this.GetFtypeCell(row.Model.TypeId));
        }
        return rowDataModel;
    }

    GetQueryColumn(columnGuid: string, expression: QueryExpressionModel) {
        const entities = Util.GetAllQueryEntities(expression);
        let result = null;

        _.each(entities, (entity) => {
            _.each(entity.Columns,
                column => {
                    if (column.Guid === columnGuid) {
                        result = column;
                    }
                });
        });

        return result;
    }

    GetQueryEntity(columnGuid: string, expression: QueryExpressionModel): QueryEntityModel {
        const entities = Util.GetAllQueryEntities(expression);
        let result = null;

        _.each(entities, (entity) => {
            _.each(entity.Columns,
                column => {
                    if (column.Guid === columnGuid) {
                        result = entity;
                    }
                });
        });

        return result;
    }

    AddToBasket(row: GridRow) {
        this.Trigger(EVENTS.ADD_TO_BASKET, {Row: row});
    }

    RemoveFromBasket(row: GridRow) {
        this.Trigger(EVENTS.REMOVE_FROM_BASKET, {Row: row});
    }

    GetTemplateName(): string {
        return 'Core/Controls/Grid/BaseGrid/Templates/BaseGrid';
    }

    AfterRender(el: Array<HTMLElement>) {
    }

    AfterGridReady(el: Array<HTMLElement>) {
        const isSameElement = this._el === el[0].parentElement;
        this._el = el[0].parentElement;
        this.ResizeTableCellsAfterRender();

        if (isSameElement) return;

        if (this._isCardScreen()) {
            this._el.classList.remove("hidden-table");
        }

        const unbindResize = ResizeService.SubscribeWidth(this.OnResize, this._el); // subscribe on resize

        ko.utils.domNodeDisposal.addDisposeCallback(this._el, () => {
            unbindResize();
            window.removeEventListener("scroll", () => this.RepositionHeaderOnScroll())
        });

        window.addEventListener("scroll", () => this.RepositionHeaderOnScroll());
        $(this._el).parents('.jBox-content').on('scroll', () => this.RepositionHeaderOnScroll());
        $(this._el).parents('.portlet-body').on('scroll', () => this.RepositionHeaderOnScroll($(this._el).parents('.portlet-body').scrollTop()));
        this._el.addEventListener("onDropDownOpen", e => {
            e.stopPropagation();
            this._preventResize = true;
            this._el.classList.add("drop-down-open");
        });
        this._el.addEventListener("onDropDownClose", e => {
            e.stopPropagation();
            this._preventResize = true;
            this._el.classList.remove("drop-down-open");
        });

        this._el.addEventListener("resizeTable", e => {
            e.stopPropagation();
            clearTimeout(this._resizeLimitInterval);
            this.Resize();
        });
    }

    private Resize(){
        this._resizeLimitInterval = setTimeout(() => {
            this.ResizeTableCellsAfterRender();
        }, 100);
    }

    StickyActionBarHeight(isModal:boolean, jBoxModal, _el): number{
        let stickyActionBarHeight = 0;
        if (isModal){
            let actionBarBlock = jBoxModal.find('#ActionBarBlock'),
                actionBarSubForm = jBoxModal.find('.actionBarSubForm'),
                stickyActionBar = actionBarBlock.length > 0 ? actionBarBlock : actionBarSubForm.length > 0 ? actionBarSubForm : null;

            if(stickyActionBar !== null && stickyActionBar.length !== 0 && stickyActionBar.css('position') === 'sticky'){
                let paddingModalPX = $(_el).parents('.jBox-content').css('padding-top'),
                    paddingModal = +paddingModalPX.substring(0, paddingModalPX.length - 2);

                stickyActionBarHeight = Math.abs(stickyActionBar.innerHeight() + paddingModal);
            }
        }
        return stickyActionBarHeight;
    }

    RepositionHeaderOnScroll = (scrollPositionInContainer?: number) => {
        const headElement = this._el.querySelector<HTMLDivElement>('.flex-grid-head');

        if (!headElement) {
            return;
        }

        const portletFullScreen = $(this._el).parents('.portlet-fullscreen').length > 0;
        const boundingRect = this._el.getBoundingClientRect();
        const modalTopPosition = Math.abs(($('.jBox-content').parents('.jBox-Modal').height() - $(window).height()) / 2);
        let fixedHeaderHeight = 0;
        let jBoxModal = $(this._el).parents('.jBox-content').parents('.jBox-Modal');
        let isModal = jBoxModal.length > 0 && jBoxModal.css('display') !== 'none';
        if (window.innerWidth > 991) {
            const headerElement: HTMLDivElement = document.querySelector("body > .page-header");
            fixedHeaderHeight = isModal ? modalTopPosition + this.StickyActionBarHeight(isModal, jBoxModal, this._el) : headerElement ? headerElement.offsetHeight : 0;
        }

        if (!portletFullScreen && boundingRect.top < fixedHeaderHeight) {
            const distanceFromTop = Math.min(boundingRect.top * -1 + fixedHeaderHeight, boundingRect.height - 28);
            headElement.style.top = Math.max(distanceFromTop, 0) + "px";
        } else {
            const portletToolbarHeight = $(this._el).parents('.portlet-body-content').find('.toolbar-wrapper').height();
            const topHeaderPosition = scrollPositionInContainer && portletToolbarHeight < scrollPositionInContainer ? scrollPositionInContainer - portletToolbarHeight - 5 + "px" : '0';
            headElement.style.top = topHeaderPosition;
        }
    };

    OnResize = () => {
        if (this._preventResize) {
            this._preventResize = false;
            return;
        }
        this.ResizeTableCellsAfterRender();
        this.RepositionHeaderOnScroll();
    };

    Expand() {
        this._visible(true);
    }

    Colapse() {
        this._visible(false);
    }

    Render(target: HTMLElement) {
        ko.cleanNode(target);
        ko.applyBindings(this, target);
    }

    GetSelectRecords(): Array<GridRow> {
        const selectedRecords = _.filter(this._rows(), (row) => row.IsSelected());
        return selectedRecords;
    }

    get Wrapper(): HTMLElement {
        return this._el;
    }

    set IsReady(value: boolean) {
        this._isReady(value);
    }

    get IsFastFilterEnabled(): boolean {
        return this._isFastFilterEnabled();
    }

    set IsFastFilterEnabled(value: boolean) {
        this._isFastFilterEnabled(value);
    }

    public GetEntityByColumnGuid(columnGuid: string, queryExpression: QueryExpressionModel): QueryEntityModel {
        let result = null;
        const entities = Util.GetAllQueryEntities(queryExpression);
        _.each(entities, (entity) => {
            const field = _.find(entity.Columns, (column) => column.Guid === columnGuid);

            if (field) {
                result = entity;
            }
        });

        return result;
    }

    GetRecordIds(): Array<number> {
        const ids = _.map(this._rows(), (row) => row.RecordId);

        return ids;
    }

    GetAllRecordKeys() {
        let result = [];
        _.each(this._rows(), (row) => {
            result = result.concat(row.Model.RecordKeys);
        });
        return result;
    }

    GetGridRowByRecordId(recordId: number): GridRow {
        return _.find(this._rows(), (row: GridRow) => row.RecordId === recordId);
    }

    GetGridRowsByRecordIds(recordIds: number[]): GridRow[] {
        return _.filter(this._rows(), (row: GridRow) => _.contains(recordIds, row.RecordId));
    }

    GetUnlinkedGridRowByRecordId(recordId: number): GridRow {
        return _.find(this._unlinkedRows, (row: GridRow) => row.RecordId === recordId);
    }

    GetUnlinkedGridRowsByRecordIds(recordIds: number[]): GridRow[] {
        return _.filter(this._unlinkedRows, (row: GridRow) => _.contains(recordIds, row.RecordId));
    }

    GetRowByIndex(index: number): GridRow {
        return this._rows()[index];
    }

	GetRowByRelation(relation: ILoadScreenRelationModel, isNewParent: boolean): GridRow {
		if (!this._rows()) {
			return null;
		}

		if (relation.KSeq) {
			return this._rows().find(row => row.KSeq === relation.KSeq);
		}

		return isNewParent
			? this._rows().find(row => row.RecordId === relation.MainRecordId)
			: this._rows().find(row => row.RecordId === relation.RelatedRecordId);
    }

    DuplicateRow(row: GridRow): GridRow {
        const newRowModel = row.Model.Clone();
        return this.AddNewRow(newRowModel, newRowModel.TypeId, newRowModel.KindId);
    }

    MarkRecodsInBasket(records: Array<number>) {
        if (this._enableBasket()) {
            _.each(this._rows(), (row: GridRow) => {
                row.MarkRecodsInBasket(records);
            });
        }
    }

    SerializeRows(): Array<GridRowDataModel> {
        let result = [];
        _.each([...this._rows(), ...this._deletedRows], (row: GridRow) => {
            if (row.State === States.New || row.State === States.Edit || row.State === States.Link || row.State === States.LinkAndEdit || row.State === States.Delete) {
                const rowDataModel = new GridRowDataModel();
                rowDataModel.Guid = row.Guid;
                rowDataModel.SubjectEntityId = this._dataModel.SubjectEntityId;
                rowDataModel.SubjectRecordId = this._dataModel.SubjectRecordId;
                rowDataModel.EntityId = row.EntityId;
                rowDataModel.RecordId = row.RecordId;
                rowDataModel.State = row.State;
                rowDataModel.KSeq = row.KSeq;
                rowDataModel.IsLinkParent = row.IsLinkParent;
                if (row.State === States.New && this.HasFTypeField() && !this.HasFTypeColumn()) {
                    rowDataModel.Data.push(this.GetFtypeCell(row.Model.TypeId));
                }
                _.each(row.DataCells, item => {
                    const aliasToBeAdded = item.EntityId == rowDataModel.EntityId && item.Model.FieldMetadata.Type === FIELD_TYPES.Alias;
                    if (!item.IsReadOnly() || item.Model.FieldMetadata.Type === FIELD_TYPES.Sort || aliasToBeAdded) {
                        const fieldData = new FieldDataModel();
                        fieldData.Value = item.Value;
                        fieldData.CustomValue = item.Model.FieldMetadata.Type == FIELD_TYPES.Lookup && item.DisplayValue;
                        fieldData.FieldId = item.FieldId;
                        fieldData.EntityId = item.EntityId;
                        fieldData.RecordKeys = item.Model.RecordKeys || [];
                        fieldData.Translations = this.GetTranslations(item);
                        rowDataModel.Data.push(fieldData);
                    }
                });
                result.push(rowDataModel);
            }
        });

        return result;
    }

    SerializeLinkEditorData(): Array<UpdateDataModel> {
        let result = [];
        let allRows = [...this._rows(), ...this._unlinkedRows];

        _.each(allRows, (row) => {
            let linkEditorData = row.SerializeLinkEditorData();
            if (linkEditorData) {
                result.push(linkEditorData);
            }
        });

        return result;
    }

    GetEditRows(): Array<GridRow> {
        let rows = _.filter(this._rows(), (row: GridRow) => {
            return row.IsEditMode;
        });
        return rows;
    }

    SaveEditRows() {
        var rows = this.GetEditRows();
        _.each(rows, (row: GridRow) => {
            row.SaveRow();
        });
    }

    GetNewAndLinkRows() {
        return this._rows().filter(
            row => row.State === States.New || row.State === States.Link || row.State === States.LinkAndEdit
        );
    }

    ReleaseLockRows() {
        let rows = this._lockRows; 
        if (this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
            const subjectEntityId = this._form.GetScreen().GetEntityId();
            const subjectRecordId = this._form.GetScreen().GetRecordId();
            rows = _.filter(rows, (row) => {
                return !(row.EntityId === subjectEntityId && row.RecordId === subjectRecordId);
            });
        }

        _.each(rows, (row: GridRow) => {
            LockManager.Instance.ReleaseLock(row.EntityId, row.RecordId);
        });
        this._lockRows = [];
    }

    HasEditRows(): boolean {
        var rows = this.GetEditRows();
        if (rows.length > 0) {
            return true;
        }
        return false;
    }

    get Model(): GridDataModel {
        return this._dataModel;
    }

    private GetTranslations(dataCell: DataCell) {
        return dataCell.Translations && dataCell.Translations.map(translation => {
            const model = new TranslationModel();

            model.LanguageShortName = TranslationManager.Instance.GetLanguageById(translation.LanguageId).ShortName;
            model.Value = translation.Value;

            return model;
        });
    }

    private HasFTypeColumn() {
        let fTypeColumn = _.find(this.GetGridSubjectEntity().Columns, column => {
            return column.Metadata.Name === TYPE_FIELD_NAME;
        });
        return !!fTypeColumn;
    }

    private HasFTypeField() {
        let fTypeField = _.find(this.GetGridSubjectEntity().Metadata.Fields, field => {
            return field.Name === TYPE_FIELD_NAME;
        });
        return !!fTypeField;
    }

    private GetFtypeCell(typeId?: number) {
        const fieldData = new FieldDataModel();
        fieldData.Value = typeId ? String(typeId) : '0';

        const objectEntity = this.GetGridSubjectEntity();

        const fTypeField = objectEntity.Metadata.Fields.find(item => item.Name === TYPE_FIELD_NAME);

        fieldData.FieldId = fTypeField.Id;
        fieldData.EntityId = objectEntity.Metadata.Id;
        fieldData.RecordKeys = [];
        fieldData.Translations = [];

        return fieldData;
    }

    GetGridSubjectEntity(): QueryEntityModel {
        if (this._screenType === ScreenTypes[ScreenTypes.ListScreen]
            || this._screenType === ScreenTypes[ScreenTypes.SpecialScreen]
            || this._screenType === ScreenTypes[ScreenTypes.QueryScreen]) {
            return this._dataModel.QueryExpression.Entity;
        }
        if (this._dataModel.QueryExpression.EntityJoins.length > 0) {
            return this._dataModel.QueryExpression.EntityJoins[0].Entity
        }

        if (this._dataModel.QueryExpression.SubEntityJoins.length > 0) {
            return this._dataModel.QueryExpression.SubEntityJoins[0].Entity
        }

        if (this._dataModel.QueryExpression.ReferenceLookupEntityJoins.length > 0) {
            return this._dataModel.QueryExpression.ReferenceLookupEntityJoins[0].Entity
        }
    }

    public SetGridDataModel(model: IGetGridDataRequestModel) {
        this._gridDataModel = model;
    }

    public ShowBulkEditor() {
        //TODO refactor

        var bulkEditRow = this.GetBulkEditRow();

        if (bulkEditRow) {

            const editingRows = _.uniq(_.map(_.filter(this._rows(), (item) => item.Model.Rights.IsEditingAllowed),
                (row) => row.RecordId));

            if (_.size(editingRows) === 0) {
                return;
            }

            const entityId = _.first(this._rows()).EntityId;

            BlockUI.Block();
            LockManager.Instance.TryLocks(entityId, editingRows)
                .then((ids) => {
                    if (ids && ids.length !== editingRows.length) {
                        new Notifier().Warning(NOTIFICATIONS.SOME_RECORDS_ARE_LOCKED);
                    }

                    if (ids && ids.length > 0) {
                        new Notifier().Success(
                            NOTIFICATIONS.BULK_EDIT_RECORD_NUMBER_WILL_BE_EDITED.replace('{records}', `${ids.length}`)
                        );
                    }

                    this.AddInLockRows(ids);

                    bulkEditRow.On(EVENTS.CANCEL_EDIT, this, (eventArgs: any) => {
                        this._bulkEditRow(null);
                        this.RemoveFromLockRows(ids);
                        LockManager.Instance.ReleaseLocks(entityId, this.GetIdsForRelease(entityId, ids));
                    });

                    bulkEditRow.On(EVENTS.SAVE_RECORD, this, (eventArgs: any) => {
                        var result = [];
                        let isDataValid = true;

                        _.each(bulkEditRow.DataCells, item => {
                            if (!item.IsDataValid() && !item.IsReadOnly()) {
                                isDataValid = false;
                            }
                        });

                        if (isDataValid) {
                            _.each(bulkEditRow.DataCells, item => {
                                item.SaveControlValue();
                            });
                        }

                        _.each(_.filter(this._rows(), item => _.contains(ids, item.RecordId)),
                            (row: GridRow) => {
                                if (isDataValid) {
                                    if (row.Model.Rights.IsEditingAllowed) {
                                        const rowDataModel = new GridRowDataModel();
                                        rowDataModel.SubjectEntityId = this._dataModel.SubjectEntityId;
                                        rowDataModel.SubjectRecordId = this._dataModel.SubjectRecordId;
                                        rowDataModel.EntityId = row.EntityId;
                                        rowDataModel.RecordId = row.RecordId;
                                        rowDataModel.State = States.Edit;
                                        rowDataModel.KSeq = row.KSeq;

                                        _.each(row.DataCells,
                                            item => {
                                                if (item.IsEnableEdit()) {
                                                    const fieldData = new FieldDataModel();

                                                    var bulkCell = _.find(bulkEditRow.DataCells,
                                                        (bulkCellItem) => {
                                                            return bulkCellItem.QueryColumnGuid ===
                                                                item.QueryColumnGuid;
                                                        });

                                                    if (bulkCell && bulkCell.IsEnableEdit()) {
                                                        fieldData.Value = bulkCell.Value;
                                                        if (bulkCell.Value && bulkCell.Value != "0") {
                                                            fieldData.FieldId = item.FieldId;
                                                            fieldData.EntityId = item.EntityId;
                                                            fieldData.RecordKeys = item.Model.RecordKeys || [];
                                                            fieldData.Translations = this.GetTranslations(item);
                                                            rowDataModel.Data.push(fieldData);
                                                        }
                                                    }
                                                }
                                            });
                                        result.push(rowDataModel);
                                    }
                                }
                            });

                        BlockUI.Block();
                        GridStore.UpdateRows({ Rows: result })
                            .always(() => {
                                BlockUI.Unblock();
                                this.RemoveFromLockRows(ids);
                                LockManager.Instance.ReleaseLocks(entityId, this.GetIdsForRelease(entityId, ids));
                            })
                            .then(data => {
                                this._bulkEditRow(null);
                                this.Trigger(EVENTS.DATA_SAVED, { UpdateResult: data });
                            });
                    });
                })
                .always(() => {
                    BlockUI.Unblock();
                });

            this._bulkEditRow(bulkEditRow);
        }
    }

    private AddInLockRows(ids: number[]) {
        _.forEach(ids,
            (id) => {
                const row = _.find(this._lockRows, (item) => item.RecordId === id);
                if (!row) {
                    const lockRow = _.find(this._rows(), (item) => item.RecordId === id);
                    if (lockRow) {
                        this._lockRows.push(lockRow);
                    }
                }
            });
    }

    private RemoveFromLockRows(ids: number[]) {
        this._lockRows = _.reject(this._lockRows, lockRow => _.contains(ids, lockRow.RecordId))
    }

    private GetIdsForRelease(entityId: number, ids: number[]) {
        if (this._screenType === ScreenTypes[ScreenTypes.EditScreen]) {
            if (entityId === this._form.GetScreen().GetEntityId()) {
                ids = _.without(ids, this._form.GetScreen().GetRecordId());
            }
        }

        return ids;
    }

	get HasInlineControls() {
		return this._inlineControls && this._inlineControls.length > 0;
	}

    GetInlineControls() {
        return _.map(this._inlineControls, (control) => {
            let inlineControl = control.Clone();
            control.GetParentControl().AddSubControl(inlineControl);
            return inlineControl;
        });
    }

    GetBulkEditControls() {
        const controls = _.filter(this._inlineControls, (control) => {
            return control.GetType() !== CONTROL_TYPES.GenericButton;
        });

        return _.map(controls, (control) => {
            return control.Clone();
        });
    }

    get DataModel(): GridDataModel {
        return this._dataModel;
    }

    GetGroupedData(cells: DataCell[]) {
        if (!cells) {
            return [];
        }

        return cells.map(cell => {
            const column = this.groupColumns[cell.QueryColumnGuid];
            const value = (cell.Model.DisplayValue || cell.ViewValue || cell.Value);
            return { Title: column.Title, Value: value, Cell: cell };
        });
    }

    SortByRelationships() {
        if (this._sortByRelationshipOrderClassName() === ''){
            return;
        }

        if (this._sortByRelationshipOrder() === SortOrder.Both) {
            this._sortByRelationshipOrder(SortOrder.Asc);
        } else if (this._sortByRelationshipOrder() === SortOrder.Asc) {
            this._sortByRelationshipOrder(SortOrder.Desc);
        } else if (this._sortByRelationshipOrder() === SortOrder.Desc) {
            this._sortByRelationshipOrder(SortOrder.Both);
        }

        this.ResetSort(null);
        let sortColumns = [];
        let sortModel = new SortModel();
        sortModel.OrderColumn = 'IsParent';
        sortModel.SortOrder = this._sortByRelationshipOrder();
        sortColumns.push(sortModel);
        this.Trigger(EVENTS.SORT, {SortColumns: sortColumns});
    }

    EditAll() {
        const editingRows = _.uniq(
            _.map(
                _.filter(this._rows(), item => item.Model.Rights.IsEditingAllowed),
                row => row.RecordId
            )
        );

        if (editingRows.length > 0) {
            new Notifier().Success(
                NOTIFICATIONS.EDIT_ALL_RECORD_NUMBER_WILL_BE_EDITED.replace('{records}', `${editingRows.length}`)
            );
        }

        _.each(this._rows(), (row) => {
            row.IsEditMode = true;
        });
    }

    SaveAll(): boolean {
        let result = true;
        let editableRows = _.filter(this._rows(), (row) => {
            return row.IsEditMode;
        });

        _.each(editableRows, (row) => {
            if (!row.SaveRow()) {
                result = false;
            }
        });

        this.ResizeTableCellsAfterRender();

        return result;
    }

    ShowOriginalImage(imageCell: DataCell) {
        let contextRow = _.find(this._rows(), (row: GridRow) => {
            return _.any(row.DataCells, (cell) => cell == imageCell);
        });

        if (contextRow) {
            let cellIdex = contextRow.DataCells.indexOf(imageCell);

            let imageCells = _.map(this._rows(), (row: GridRow) => {
                return row.DataCells[cellIdex];
            });

            imageCells = _.filter(imageCells, (cell: DataCell) => {
                return cell && !_.isEmpty(cell.DisplayValue);
            });


            var opt = {
                markerable: false, 
                button: true,
                inline: false,
                navbar: false,
                title: false,
                toolbar: true,
                tooltip: true,
                movable: true,
                zoomable: true,
                rotatable: true,
                scalable: true,
                transition: true,
                fullscreen: true,
                keyboard: true,
                url: 'data-original',
                zIndex: ZIndexManager.Instance.NextValue,
                filter() {
                    return true;
                }
            };

            let container = $("<div/>");

            _.each(imageCells, (imageCell) => {
                let node = imageCell.Wrapper.cloneNode() as HTMLElement;
                container.append(node);
            });


            let viewer = new Viewer(container[0], opt);
            let baseNext = viewer.next;

            viewer.next = (loop?: boolean) => {
                let index = viewer.index + 1;

                if (index === imageCells.length) {
                    index = 0;
                }

                let cell = imageCells[index];

                if (cell) {
                    this.LoadOriginalImage(cell, index, viewer, index == 0 ? null : baseNext);
                }
                return viewer;
            };

            let basePrev = viewer.prev;
            viewer.prev = (loop?: boolean) => {
                let index = viewer.index - 1;

                if (index < 0) {
                    index = imageCells.length - 1;
                }

                let cell = imageCells[index];

                if (cell) {
                    this.LoadOriginalImage(cell, index, viewer, index == imageCells.length - 1 ? null : basePrev);
                }
                return viewer;
            };
            this.LoadOriginalImage(imageCell, imageCells.indexOf(imageCell), viewer, null);
        }
    }

    private LoadOriginalImage(imageCell: DataCell, index: number, viewer: Viewer, navigationAction: Function) {
        BlockUI.Block();
        GridStore.GetImageValue({
            EntityId: imageCell.Model.EntityMetadata.Id,
            FieldId: imageCell.Model.FieldMetadata.Id,
            RecordKeys: imageCell.Model.RecordKeys
        }).always(() => {
            BlockUI.Unblock();
        }).then(originalImage => {

            if (viewer.items) {
                let item = viewer.items[index];
                let img = item.querySelector('img');
                $(img).attr('data-original-url', `data:image/jpeg;base64,${originalImage.DisplayValue}`);
            } else {
                let img = viewer.images[index];
                $(img).attr('src', `data:image/jpeg;base64,${originalImage.DisplayValue}`);
            }

            if (navigationAction) {
                navigationAction.call(viewer);
            } else {
                viewer.view(index);
            }

            ViewerJsExtention.CustomTooltip();

        }).fail((err) => {
            new Notifier().Failed(err.message);
        });
    }

    get Paginator(): Paginator {
        return this._toolbar.Paginator();
    }
}
