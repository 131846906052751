import * as $ from 'jquery';
import * as _ from 'underscore';
import * as ko from 'knockout';

import {ScreenModel} from 'Core/Models/Screens/ScreenModel';

import {ResizeObserver} from 'Core/Common/ResizeObserver';

// Templates
import Template from 'Core/Screens/Templates/AdditionalConsultScreen/ProcessCardScreen/ProcessCardScreen.html';
import { EditScreen } from '../EditScreen/EditScreen';

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/ProcessCardScreen/ProcessCardScreen'] = Template;

export class ProcessCardPage extends EditScreen {
   
    constructor(screenModel: ScreenModel) {
        super(screenModel);
    }

    GetTemplateName(): string {
        return 'Core/Screens/Templates/AdditionalConsultScreen/ProcessCardScreen/ProcessCardScreen';
    }
}