import { CONTROL_TYPES } from 'Core/Constant';
import * as ko from 'knockout';

import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {ScreenDataModel} from 'Core/ScreenManager/Models/ScreenDataModel'
import {SpecialScreen} from 'Core/Screens/SpecialScreen/SpecialScreen';

import Template from 'Core/Screens/Templates/AdditionalConsultScreen/StepsScreen/StepsScreen.html';
import { Step } from '../../Controls/Step/Step';

ko.templates['Core/Screens/Templates/AdditionalConsultScreen/StepsScreen/StepsScreen'] = Template;

export class StepsScreen extends SpecialScreen {
    constructor(screenModel: ScreenModel) {
        super(screenModel);
    }

    Init() {
        if (!this._model.Data) {
            return;
        }

        this.SetData(this._model.Data);

        this.On('SEARCH_RECORD', this, (eventArgs: any) => {
            this.SearchRecord();
        });
        this.On('EDIT_RECORD', this, (eventArgs: any) => {
            if (eventArgs.data && eventArgs.data.TableTypeId && eventArgs.data.RecordId) {
                this.EditRecord(eventArgs.data.RecordId, eventArgs.data.TableTypeId);
            } else {
                this.EditRecord();
            }
        });

        this.On('NEW_RECORD', this, (eventArgs: any) => {
            var screenData = null;
            if (eventArgs.data && eventArgs.data.ScreenData instanceof ScreenDataModel) {
                screenData = eventArgs.data.ScreenData;
            }

            if (eventArgs.data && eventArgs.data.TableTypeId) {
                this.NewRecord(eventArgs.data.TableTypeId, 0, screenData);
            } else {
                this.ShowTypeScreen(screenData);
            }
        });

        this.On('ADD_RECORD', this, (eventArgs: any) => {
            this.ShowProfileSelector();
        });
    }

    GetTemplateName(): string {
        return 'Core/Screens/Templates/AdditionalConsultScreen/StepsScreen/StepsScreen';
    }

    LoadStepControlData({recordId}: {recordId: number }){
        const stepControl: Step = this.GetControlByType(CONTROL_TYPES.Step) as Step;
        if(stepControl){
            stepControl.On('FINISH_PROCESS', this, ()=>{
                this.Trigger('FINISH_PROCESS');
                this._modal.Close();
            });
            stepControl.SelectRecord2({ recordId });
        }
    }
}